import { gql } from "@apollo/client";

export const PHOTO_DATA = gql`
  fragment PhotoData on Photo {
    url
    thumbnailUrl
    approved
    verified
    uploadId
    uploadComplete
    isPrivate
    uploadById
  }
`;

export const VIDEO_DATA = gql`
  fragment VideoData on Video {
    _id
    url
    thumbnailUrl
    thumbnailBlurhash
    approved
    verified
    uploadId
    uploadComplete
    isPrivate
  }
`;

export const PAGING_DATA = gql`
  fragment PagingData on PagingResult {
    total
    limit
    page
    totalPages
    offset
    hasPrevPage
    hasNextPage
    prevPage
    nextPage
    pagingCounter
  }
`;

export const HELLO = gql`
  query hello {
    hello
  }
`;

export const DASHBOARD = gql`
  query dashboard {
    dashboard {
      totalUsers
      totalGroups
      totalInvites
      totalUnverifiedPhotos
      totalUnresolvedReports
      totalBannedUsers
      totalUnverifiedUsers
      totalPrivatePhotos
      dailyActiveUsers
      totalUsersWithApprovedPhoto
    }
  }
`;

export const USER = gql`
  ${PHOTO_DATA}
  query user($_id: String!) {
    user(_id: $_id) {
      _id
      username
      email
      deleted
      premium
      admin
      credits
      lastActive
      loginDate
      banned
      bannedUntilDate
      translationTokens
      profilePhoto {
        ...PhotoData
      }
      profilePhotoApproved
      photos {
        ...PhotoData
      }
    }
  }
`;

export const UNVERIFIED_PHOTOS = gql`
  ${PAGING_DATA}
  ${PHOTO_DATA}
  query unverifiedPhotos($paging: PagingArgs!) {
    unverifiedPhotos(paging: $paging) {
      paging {
        ...PagingData
      }
      nodes {
        ...PhotoData
      }
    }
  }
`;

export const UNVERIFIED_VIDEOS = gql`
  ${PAGING_DATA}
  ${VIDEO_DATA}
  query unverifiedVideos($paging: PagingArgs!) {
    unverifiedVideos(paging: $paging) {
      paging {
        ...PagingData
      }
      nodes {
        ...VideoData
      }
    }
  }
`;

export const PRIVATE_PHOTOS = gql`
  ${PAGING_DATA}
  ${PHOTO_DATA}
  query privatePhotos($paging: PagingArgs!) {
    privatePhotos(paging: $paging) {
      paging {
        ...PagingData
      }
      nodes {
        ...PhotoData
      }
    }
  }
`;

export const PRIVATE_VIDEOS = gql`
  ${PAGING_DATA}
  ${VIDEO_DATA}
  query privateVideos($paging: PagingArgs!) {
    privateVideos(paging: $paging) {
      paging {
        ...PagingData
      }
      nodes {
        ...VideoData
      }
    }
  }
`;

export const USERS = gql`
  ${PAGING_DATA}
  query users($regex: String, $paging: PagingArgs!) {
    users(regex: $regex, paging: $paging) {
      paging {
        ...PagingData
      }
      nodes {
        _id
        username
        email
        deleted
        premium
        admin
        credits
        lastActive
        loginDate
        banned
        bannedUntilDate
        translationTokens
      }
    }
  }
`;

export const PROFILE_PHOTOS_APPROVAL = gql`
  ${PHOTO_DATA}
  query profilePhotosApproval {
    profilePhotosApproval {
      _id
      username
      platform
      country
      profilePhoto {
        ...PhotoData
      }
    }
  }
`;

export const MATCH_VIDEOS_APPROVAL = gql`
  ${PHOTO_DATA}
  query matchVideosApproval {
    matchVideosApproval {
      _id
      username
      profilePhoto {
        ...PhotoData
      }
      matchVideo {
        url
        approved
        verified
        uploadId
        uploadComplete
        isPrivate
        thumbnailUrl
        thumbnailBlurhash
      }
    }
  }
`;

export const REELS_APPROVAL = gql`
  query reelsApproval {
    reelsApproval {
      reels {
        _id
        url
        thumbnailUrl
        thumbnailBlurhash
        uploadId
        uploadComplete
        byUserId
        description
      }
      latestReels {
        _id
        url
        thumbnailUrl
        thumbnailBlurhash
        uploadId
        uploadComplete
        byUserId
        description
      }
    }
  }
`;

export const UNRESOLVED_REPORTS = gql`
  query unresolvedReports {
    unresolvedReports {
      _id
      byUserId
      type
      problem
      message
      contentId
      resolved
      resolvedDate
      resolvedByEmail
      created
    }
  }
`;

export const NEWS = gql`
  query news($limit: Int!, $lang: String) {
    news(limit: $limit, lang: $lang) {
      _id
      title
      subtitle
      message
      date
    }
  }
`;

export const PHOTO_VERIFICATIONS = gql`
  ${PHOTO_DATA}
  query photoVerifications {
    photoVerifications {
      userId
      profilePhoto {
        ...PhotoData
      }
      verifyPhoto {
        ...PhotoData
      }
    }
  }
`;

export const PUBLIC_POSTS_PENDING_REVIEW = gql`
  query publicPostsPendingReview {
    publicPostsPendingReview {
      _id
      byUserId
      audience
      created
      text
      reviewStatus
      attachments {
        url
        thumbnailUrl
        uploadComplete
        uploadId
      }
    }
  }
`;

export const ANALYTICS = gql`
  query analytics {
    analytics {
      totalVerifiedUsers
      totalFemaleUsers
      totalMaleUsers
      totalOtherGenderUsers
      totalUsersWithPremium
      totalPayingUsers
      totalActiveUsersLast30days
      totalPayingUsersLast30days
      totalActiveUsersLast30daysCreatedMoreThan90daysAgo
      totalNewUsersLast30days
      daus {
        date
        users
        mau
      } # Daily active users for last 60 days. One item per day.
      totalUsers {
        date
        users
      } # Total users for last year. One item per month.
      totalUsersPlatformIos
      totalUsersPlatformAndroid
      totalUsersPlatformWeb
      totalUsersLoginApple
      totalUsersLoginFacebook
      totalUsersLoginGoogle
      totalUsersLoginEmail
      totalUsersByCountry {
        country
        users
      }
    }
  }
`;

export const GROUPS = gql`
  ${PHOTO_DATA}
  query groups {
    groups {
      _id
      created
      privacy
      visibility
      memberVisibility
      memberPostsAllowed
      memberCommentsAllowed
      name
      about
      rules
      genderLimited
      minAgeLimited
      maxAgeLimited
      photo {
        ...PhotoData
      }
    }
  }
`;

export const GROUP_PHOTOS = gql`
  ${PHOTO_DATA}
  query groupPhotos {
    groupPhotos {
      _id
      photo {
        ...PhotoData
      }
    }
  }
`;

export const SUBSCRIPTIONS = gql`
  query subscriptions {
    subscriptions {
      totalSubscriptions
      data {
        userId
        expireDate
        age
        country
      }
    }
  }
`;

export const USER_ACTIVITY_MAP = gql`
  ${PHOTO_DATA}
  query userActivityMap($minutes: Int!, $filters: [UserActivityFilter!]!) {
    userActivityMap(minutes: $minutes, filters: $filters) {
      data {
        lastActive
        userId
        username
        lat
        lng
        photo {
          ...PhotoData
        }
        country
        city
        created
        birthDate
      }
    }
  }
`;

import { useTheme } from "@mui/material";
import Card from "../../components/Card/Card";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { UsersIcon, UserIcon, CameraIcon } from "@heroicons/react/24/solid";
import { DASHBOARD } from "../../graphql/queries-gql";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader/Loader";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import { useNavigate } from "react-router";
import { CALC_POPULARITY, FIX_PHOTOS } from "../../graphql/mutations-gql";
import { StdButton } from "../../components/Button/Button";

const Dashboard = () => {
  const theme = useTheme();
  const { data, loading, error } = useQuery(DASHBOARD, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("onCompleted", data);
    },
  });
  const navigate = useNavigate();
  const [calcPopularity] = useMutation(CALC_POPULARITY);
  const [fixPhotos] = useMutation(FIX_PHOTOS);

  const handleCalcPopularity = () => {
    calcPopularity({});
  };

  if (loading) {
    return (
      <FlexCol>
        <FlexRow justifyContent="center">
          <Loader />
        </FlexRow>
      </FlexCol>
    );
  }

  if (error) {
    return (
      <FlexCol>
        <FlexRow justifyContent="center">
          <ErrorAlert error={error} />
        </FlexRow>
      </FlexCol>
    );
  }

  return (
    <>
      <FlexCol padding={0}>
        <FlexRow colGap={20}>
          <Card width={180} height={80} onClick={() => navigate("/users")}>
            <FlexCol>
              <Text weight={600}>DAU</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <UserIcon width={30} color={theme.colors.primary} />
                <Text weight={600} size={24}>
                  {data.dashboard.dailyActiveUsers}
                </Text>
              </FlexRow>
            </FlexCol>
          </Card>
        </FlexRow>
        <FlexRow colGap={20}>
          <Card width={180} height={80} onClick={() => navigate("/users")}>
            <FlexCol>
              <Text weight={600}>Verified users</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <UserIcon width={30} color={theme.colors.green} />
                <Text weight={600} size={24}>
                  {data.dashboard.totalUsers}
                </Text>
              </FlexRow>
            </FlexCol>
          </Card>
          <Card width={180} height={80} onClick={() => navigate("/users")}>
            <FlexCol>
              <Text weight={600}>With photo</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <UserIcon width={30} color={theme.colors.green} />
                <Text weight={600} size={24}>
                  {data.dashboard.totalUsersWithApprovedPhoto}
                </Text>
              </FlexRow>
            </FlexCol>
          </Card>
          <Card width={180} height={80}>
            <FlexCol>
              <Text weight={600}>Deactivated users</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <UserIcon width={30} color={theme.colors.gray} />
                <Text weight={600} size={24}>
                  0
                </Text>
              </FlexRow>
            </FlexCol>
          </Card>

          <Card width={180} height={80}>
            <FlexCol>
              <Text weight={600}>Unverified users</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <UserIcon width={30} color={theme.colors.red} />
                <Text weight={600} size={24}>
                  {data.dashboard.totalUnverifiedUsers}
                </Text>
              </FlexRow>
            </FlexCol>
          </Card>

          <Card width={180} height={80}>
            <FlexCol>
              <Text weight={600}>Invited users</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <UsersIcon width={30} color={theme.colors.orange} />
                <Text weight={600} size={24}>
                  {data.dashboard.totalInvites}
                </Text>
              </FlexRow>
            </FlexCol>
          </Card>
        </FlexRow>

        <FlexRow colGap={20}>
          <Card width={180} height={80}>
            <FlexCol>
              <Text weight={600}>Groups</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <UsersIcon width={30} color={theme.colors.green} />
                <Text weight={600} size={24}>
                  {data.dashboard.totalGroups}
                </Text>
              </FlexRow>
            </FlexCol>
          </Card>
          <Card
            width={180}
            height={80}
            onClick={() => navigate("/unverified-photos")}
          >
            <FlexCol>
              <Text weight={600}>Unverified photos</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <CameraIcon width={30} color={theme.colors.orange} />
                <Text weight={600} size={24}>
                  {data.dashboard.totalUnverifiedPhotos}
                </Text>
              </FlexRow>
            </FlexCol>
          </Card>
          {/* <Card width={180} height={80}>
            <FlexCol>
              <Text weight={600}>Unresolved reports</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <UserIcon width={30} />
                <Text weight={600} size={24}>
                  {data.dashboard.totalUnresolvedReports}
                </Text>
              </FlexRow>
            </FlexCol>
          </Card> */}
          <Card width={180} height={80}>
            <FlexCol>
              <Text weight={600}>Banned users</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <UserIcon width={30} />
                <Text weight={600} size={24}>
                  {data.dashboard.totalBannedUsers}
                </Text>
              </FlexRow>
            </FlexCol>
          </Card>
        </FlexRow>

        <FlexRow colGap={20}>
          <Card width={180} height={80}>
            <FlexCol>
              <Text weight={600}>Private photos</Text>
              <FlexRow justifyContent="center" colGap={10}>
                <CameraIcon width={30} color={theme.colors.primary} />
                <Text weight={600} size={24}>
                  {data.dashboard.totalPrivatePhotos}
                </Text>
              </FlexRow>
            </FlexCol>
          </Card>
        </FlexRow>

        <FlexRow>
          <StdButton onClick={() => handleCalcPopularity()}>
            Calc popularity
          </StdButton>
        </FlexRow>
        {/* <FlexRow>
          <StdButton onClick={() => fixPhotos({})}>Fix photos</StdButton>
        </FlexRow> */}
      </FlexCol>
    </>
  );
};
export default Dashboard;

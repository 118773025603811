import styled, { css } from "styled-components";

const CardContainer = styled.div<{ width?: number; height?: number }>`
  padding: 20px;
  background-color: ${(props) => props.theme.cardBg};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  ${(props) => (props.width !== undefined ? `width: ${props.width}px;` : "")}
  ${(props) => (props.height !== undefined ? `height: ${props.height}px;` : "")}
  ${(props) =>
    props.onClick !== undefined
      ? css`
          &:hover {
            cursor: pointer;
          }
        `
      : ""}
`;

const Card: React.FC<{
  children?: React.ReactNode;
  width?: number;
  height?: number;
  onClick?: () => void;
}> = (props) => {
  return <CardContainer {...props}>{props.children}</CardContainer>;
};
export default Card;

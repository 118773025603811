import { useTheme } from "@mui/material";
import styled from "styled-components";
import StyledButton from "../../components/Button/Button";
import Text from "../../components/Styled/Text";

const IconImage = styled.img`
  width: 26px;
  height: 26px;
`;

const GoogleButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const theme = useTheme();

  return (
    <>
      <StyledButton
        onClick={(event: any) => onClick()}
        width="100%"
        borderRadius="8px"
        justifyContent="flex-start"
        backgroundColor="#000"
        color={theme.colors.gray}
        height="44px"
        colGap="10px"
      >
        <IconImage src="/googleicon.png" />
        <Text size={16} weight={600}>
          Continue with Google
        </Text>
      </StyledButton>
    </>
  );
};
export default GoogleButton;

import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import Cookies from "js-cookie";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const devApiUrl = "https://localhost:4001";
const prodApiUrl = "https://api-backoffice.minglify.io/graphql";

//const apiUrl = process.env.NODE_ENV === "development" ? devApiUrl : prodApiUrl;
const apiUrl = prodApiUrl;

const apiLink = createHttpLink({
  uri: apiUrl,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if (
      networkError.message ===
      "Response not successful: Received status code 401"
    ) {
      // Means we are not authorized. We should then redirect to the login screen.
      Cookies.remove("session_token");
      sessionStorage.removeItem("auth-storage");
      sessionStorage.removeItem("chat-storage");
      sessionStorage.removeItem("notification-storage");
    }
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: 3000,
  },
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // TODO, maybe calling getItemAsync is slow.. perhaps should store the token in a state.
  // but then we need to make sure that the state is updated when the token is updated.
  const token = localStorage.getItem("auth_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, retryLink, authLink.concat(apiLink)]),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App client={client} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  CameraIcon,
  ChartBarIcon,
  CheckBadgeIcon,
  EnvelopeIcon,
  EyeSlashIcon,
  FilmIcon,
  GlobeAltIcon,
  HomeIcon,
  PhotoIcon,
  QuestionMarkCircleIcon,
  TrophyIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import { useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import { FlexRowHover } from "../Styled/Container";
import Text from "../Styled/Text";

const MenuContainer = styled.div<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? 50 : 180)}px;
  height: 100%;
  background-color: ${(props) => props.theme.menuBg};
  position: fixed;
  padding: ${(props) => (props.isMobile ? 5 : 20)}px;
  padding-top: 80px;
  row-gap: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const MenuItem: React.FC<{
  title: string;
  path: string;
  external?: boolean;
  Icon?: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  >;
}> = ({ title, path, external, Icon }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locPath = location.pathname;
  const selected = path === locPath;
  const theme = useTheme();
  const windowSize = useWindowSize();
  const isMobile = windowSize[0] < 600;

  const handleClick = () => {
    if (external) {
      window.location.href = path;
    } else {
      navigate(path);
    }
  };

  return (
    <FlexRowHover
      onClick={() => handleClick()}
      selected={selected}
      selectedBackgroundColor={theme.inputBg}
    >
      {Icon && (
        <Icon
          width={24}
          color={selected ? theme.colors.primary : theme.colors.blue}
        />
      )}
      {!isMobile && <Text>{title}</Text>}
    </FlexRowHover>
  );
};

const NavMenu = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize[0] < 600;
  const theme = useTheme();

  return (
    <MenuContainer isMobile={isMobile}>
      <MenuItem title="Dashboard" path="/" Icon={HomeIcon} />
      <MenuItem
        title="Activity map"
        path="/user-activity"
        Icon={GlobeAltIcon}
      />
      <MenuItem
        title="Profile photos"
        path="/profile-photos"
        Icon={CameraIcon}
      />
      <MenuItem
        title="Public photos"
        path="/unverified-photos"
        Icon={PhotoIcon}
      />
      <MenuItem title="Reels" path="/reels" Icon={FilmIcon} />
      <MenuItem
        title="Public videos"
        path="/unverified-videos"
        Icon={FilmIcon}
      />
      <MenuItem
        title="Photo verify"
        path="/photo-verify"
        Icon={CheckBadgeIcon}
      />
      <MenuItem title="Public posts" path="/post-review" Icon={GlobeAltIcon} />
      <MenuItem title="Groups" path="/groups" Icon={UsersIcon} />
      <MenuItem title="Group photos" path="/group-photos" Icon={PhotoIcon} />
      <MenuItem
        title="Match videos"
        path="/match-videos"
        Icon={VideoCameraIcon}
      />

      <MenuItem title="Analytics" path="/analytics" Icon={ChartBarIcon} />

      <MenuItem title="Subscriptions" path="/subscriptions" Icon={TrophyIcon} />
      <MenuItem title="Users" path="/users" Icon={UsersIcon} />
      {/* <MenuItem title="Groups" path="/groups" />
      <MenuItem title="Chats" path="/groups" /> */}

      {/* <MenuItem title="Approved photos" path="/users" /> */}
      <MenuItem
        title="Private photos"
        path="/private-photos"
        Icon={EyeSlashIcon}
      />
      <MenuItem title="Private videos" path="/private-videos" Icon={FilmIcon} />
      <MenuItem title="Attachments" path="/attachments" Icon={EnvelopeIcon} />

      {/* <MenuItem title="Unresolved reports" path="/unresolved-reports" /> */}
      {/* <MenuItem title="Credits history" path="/users" /> */}
      {/* <MenuItem title="Banned users" path="/users" /> */}
      {/* <MenuItem title="Banned IPs" path="/users" /> */}
      {/* <MenuItem title="Blacklist emails" path="/users" /> */}
      {/* <MenuItem title="Admin logs" path="/users" /> */}
      {/* <MenuItem title="News" path="/news" Icon={NewspaperIcon} /> */}

      <MenuItem
        Icon={QuestionMarkCircleIcon}
        title="Helpdesk"
        external={true}
        path="https://minglify.freshdesk.com/a/dashboard/default"
      />
    </MenuContainer>
  );
};
export default NavMenu;

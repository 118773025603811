import styled from "styled-components";

export const PhotoThumbnail = styled.img<{ src: string; size?: number }>`
  width: ${(props) => (props.size ? props.size : 300)}px;
  height: ${(props) => (props.size ? props.size : 300)}px;
  object-fit: cover;
  border-radius: 10px;
`;
export const PhotoContainer = styled.div<{ size?: number }>`
  position: relative;
  width: ${(props) => (props.size ? props.size : 300)}px;
  height: ${(props) => (props.size ? props.size : 300)}px;

  &:hover {
    cursor: pointer;

    .action-container {
      display: flex;
    }
  }
`;

export const PhotoIconContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 5px;
  top: 5px;
  display: none;
  flex-direction: row;
  column-gap: 5px;
  z-index: 100;
  justify-content: flex-start;
`;

export const PhotoActionContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 5px;
  display: none;
  flex-direction: row;
  column-gap: 20px;
  z-index: 100;
  justify-content: center;
`;

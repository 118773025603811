import { useLazyQuery } from "@apollo/client";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Map, MapCameraChangedEvent, useMap } from "@vis.gl/react-google-maps";
import { addMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import { StyledTextLink } from "../../components/Styled/StyledLink";
import Text from "../../components/Styled/Text";
import {
  UserActivityFilter,
  UserActivityMapResult,
} from "../../graphql/graphql";
import { USER_ACTIVITY_MAP } from "../../graphql/queries-gql";
import { timeSinceString } from "../../util/helpers";
import ClusteredPoiMarkers, { Poi } from "./ClusteredPoiMarkers";

// https://developers.google.com/codelabs/maps-platform/maps-platform-101-react-js#0

const UserActivityMap = () => {
  const [minutes, setMinutes] = useState<number>(5);
  const [filters, setFilters] = useState<UserActivityFilter[]>([]);
  const [pois, setPois] = useState<Poi[]>([]);
  const [fetchUserActivityMap, { data, loading, error }] = useLazyQuery<{
    userActivityMap: UserActivityMapResult;
  }>(USER_ACTIVITY_MAP, {
    fetchPolicy: "network-only",
    variables: {
      minutes,
      filters,
    },
    onCompleted: (data) => {
      // console.log(
      //   "fetchUserActivityMap onCompleted",
      //   JSON.stringify(data, null, 2)
      // );
      console.log(
        "fetchUserActivityMap onCompleted",
        data.userActivityMap.data.length
      );

      const newPois = data.userActivityMap.data.map((activity) => {
        return {
          key: activity.userId,
          location: {
            lat: activity.lat,
            lng: activity.lng,
          },
          userActivityMapData: activity,
        };
      });
      setPois(newPois);
    },
    onError: (error) => {
      console.log("fetchUserActivityMap onError", error);
    },
  });
  const map = useMap();

  // Do an initial fetch
  useEffect(() => {
    console.log("fetching initial user activity map data");
    fetchUserActivityMap();
  }, [fetchUserActivityMap]);

  // Lets create an interval to refetch the data
  useEffect(() => {
    const timer = minutes <= 30 ? 30000 : 1000 * 60 * 5;
    console.log(
      `setting refetch interval based on ${minutes} minutes to ${timer}ms`
    );
    const interval = setInterval(() => {
      console.log("refetching user activity map data");
      fetchUserActivityMap();
    }, timer);

    return () => clearInterval(interval);
  }, [minutes, filters, fetchUserActivityMap]);

  const isChecked = (filter: UserActivityFilter): boolean => {
    return filters.includes(filter);
  };
  const handleCheck = (filter: UserActivityFilter, checked: boolean) => {
    let newFilters = filters.filter((f) => f !== filter);
    if (checked) {
      newFilters.push(filter);
    }

    // Some filters should not be allowed to be combined
    if (filter === UserActivityFilter.NewSignups) {
      newFilters = newFilters.filter(
        (f) => f !== UserActivityFilter.OldSignups
      );
    }
    if (filter === UserActivityFilter.OldSignups) {
      newFilters = newFilters.filter(
        (f) => f !== UserActivityFilter.NewSignups
      );
    }
    if (filter === UserActivityFilter.Men) {
      newFilters = newFilters.filter((f) => f !== UserActivityFilter.Women);
    }
    if (filter === UserActivityFilter.Women) {
      newFilters = newFilters.filter((f) => f !== UserActivityFilter.Men);
    }

    setFilters(newFilters);
  };

  return (
    <>
      <FlexCol>
        <FlexRow>
          <Text weight={600}>User activity map</Text>
        </FlexRow>
        <FlexRow>
          <StyledTextLink to="" onClick={() => setMinutes(5)}>
            5 min
          </StyledTextLink>
          <Text>{"|"}</Text>
          <StyledTextLink to="" onClick={() => setMinutes(15)}>
            15 min
          </StyledTextLink>
          <Text>{"|"}</Text>
          <StyledTextLink to="" onClick={() => setMinutes(30)}>
            30 min
          </StyledTextLink>
          <Text>{"|"}</Text>
          <StyledTextLink to="" onClick={() => setMinutes(60)}>
            1 hour
          </StyledTextLink>
          <Text>{"|"}</Text>
          <StyledTextLink to="" onClick={() => setMinutes(60 * 3)}>
            3 hours
          </StyledTextLink>
          <Text>{"|"}</Text>
          <StyledTextLink to="" onClick={() => setMinutes(60 * 8)}>
            8 hours
          </StyledTextLink>
          <Text>{"|"}</Text>
          <StyledTextLink to="" onClick={() => setMinutes(60 * 24)}>
            24 hours
          </StyledTextLink>
        </FlexRow>
        <FlexRow>
          <FormControlLabel
            label={<Text>Premium</Text>}
            control={
              <Checkbox
                checked={isChecked(UserActivityFilter.Premium)}
                onChange={(event, checked) => {
                  handleCheck(UserActivityFilter.Premium, checked);
                }}
              />
            }
          />
          <FormControlLabel
            label={<Text>New users</Text>}
            control={
              <Checkbox
                checked={isChecked(UserActivityFilter.NewSignups)}
                onChange={(event, checked) => {
                  handleCheck(UserActivityFilter.NewSignups, checked);
                }}
              />
            }
          />
          <FormControlLabel
            label={<Text>Old users</Text>}
            control={
              <Checkbox
                checked={isChecked(UserActivityFilter.OldSignups)}
                onChange={(event, checked) => {
                  handleCheck(UserActivityFilter.OldSignups, checked);
                }}
              />
            }
          />
          <FormControlLabel
            label={<Text>Men</Text>}
            control={
              <Checkbox
                checked={isChecked(UserActivityFilter.Men)}
                onChange={(event, checked) => {
                  handleCheck(UserActivityFilter.Men, checked);
                }}
              />
            }
          />
          <FormControlLabel
            label={<Text>Women</Text>}
            control={
              <Checkbox
                checked={isChecked(UserActivityFilter.Women)}
                onChange={(event, checked) => {
                  handleCheck(UserActivityFilter.Women, checked);
                }}
              />
            }
          />
        </FlexRow>
        <FlexRow>
          <Map
            mapId="2465c920699db95c"
            style={{ width: "1000px", height: "800px" }}
            defaultZoom={2}
            defaultCenter={{ lat: 35.613266732528366, lng: 9.880013000000014 }}
            onCameraChanged={
              (ev: MapCameraChangedEvent) => {}
              // console.log(
              //   "camera changed:",
              //   ev.detail.center,
              //   "zoom:",
              //   ev.detail.zoom
              // )
            }
            onZoomChanged={(ev) => {
              console.log("zoom changed:", ev.detail.zoom);
            }}
          >
            {pois && <ClusteredPoiMarkers pois={pois} />}
          </Map>
        </FlexRow>
        <FlexRow>
          <Text size={22} weight={600}>
            {pois.length} users active in last{" "}
            {timeSinceString(addMinutes(new Date(), -minutes))}
          </Text>
        </FlexRow>
        <FlexRow>
          <StyledTextLink
            to=""
            onClick={() => {
              map?.setZoom(2);
              map?.setCenter({
                lat: 35.613266732528366,
                lng: 9.880013000000014,
              });
            }}
          >
            Reset zoom
          </StyledTextLink>
        </FlexRow>
      </FlexCol>
    </>
  );
};
export default UserActivityMap;

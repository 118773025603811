import { ApolloProvider } from "@apollo/client";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { APIProvider } from "@vis.gl/react-google-maps";
import React, { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./App.css";
import router from "./routes/router";
import { darkTheme, lightTheme } from "./theme/MuiTheme"; // styled components theme

const App: React.FC<{ client: any }> = ({ client }) => {
  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  return (
    <Suspense fallback="loading">
      <ApolloProvider client={client}>
        <APIProvider
          apiKey={"AIzaSyA7jBJuZSVoyM4ZTJXfWAT4RsAQHp5lRpE"}
          onLoad={() => console.log("Maps API has loaded.")}
        >
          <GoogleOAuthProvider clientId="1027171008430-juhsmldquismg4kvek5kpfiqqdtmspcr.apps.googleusercontent.com">
            <MuiThemeProvider theme={prefersDark ? darkTheme : lightTheme}>
              <ThemeProvider theme={prefersDark ? darkTheme : lightTheme}>
                <RouterProvider router={router} />
              </ThemeProvider>
            </MuiThemeProvider>
          </GoogleOAuthProvider>
        </APIProvider>
      </ApolloProvider>
    </Suspense>
  );
};

export default App;

import { MutableRefObject, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const IconContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 11px;
  z-index: 110;
`;

const InputDisabled = styled.input<{ minWidth?: number }>`
  border: 0;
  border-color: ${(props) => props.theme.inputBg};
  border-radius: 50px;
  height: 40px;
  background-color: ${(props) => props.theme.inputBg};
  padding-left: 10px;
  padding-right: 10px;
  outline: 0 !important;
  min-width: ${(props) =>
    props.minWidth !== undefined ? props.minWidth + "px" : ""};
  //width: 100%;

  ${(props) =>
    props.onClick !== undefined
      ? css`
          &:hover {
            //background-color: #d1d1d1;
            opacity: 0.8;
            cursor: pointer;
          }
        `
      : ""}
`;

const Input = styled.input`
  position: relative;
  border: 0;
  border-color: ${(props) => props.theme.inputBg};
  border-radius: 50px;
  height: 40px;
  background-color: ${(props) => props.theme.inputBg};
  padding-left: 20px;
  padding-right: 20px;
  outline: 0 !important;
  width: 100%;
  color: ${(props) => props.theme.textColor};

  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
`;

const TextInput: React.FC<{
  className?: string;
  placeHolder?: string | null;
  disabled?: boolean;
  onClick?: (event: any) => void;
  onSubmit?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  focus?: boolean;
  allowEmpty?: boolean;
  minWidth?: number;
  icon?: JSX.Element;
}> = ({
  placeHolder,
  disabled,
  onClick,
  className,
  onSubmit,
  onFocus,
  onBlur,
  focus,
  onChange,
  allowEmpty,
  minWidth,
  icon,
}) => {
  const [value, setValue] = useState("");
  const textInput: MutableRefObject<any> = useRef(null);
  const [internalFocus, setInternalFocus] = useState(focus);

  useEffect(() => {
    if (focus) {
      textInput?.current?.focus?.();
    }
  }, [focus]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      console.log("enter pressed " + value);
      if ((value || allowEmpty) && onSubmit) {
        onSubmit(value);
      }
    }
  };

  if (disabled) {
    return (
      <InputDisabled
        ref={textInput}
        minWidth={minWidth}
        value={value}
        className={className}
        placeholder={placeHolder ?? ""}
        readOnly={true}
        //disabled={disabled}
        onFocus={() => {
          textInput.current.blur();
        }}
        onClick={(event) => {
          console.log("onClick");
          if (onClick !== undefined) {
            onClick(event);
          }
        }}
      />
    );
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    setInternalFocus(false);
  };
  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
    setInternalFocus(true);
  };

  const getPlaceHolderText = () => {
    if (icon && !internalFocus && (!value || value.length < 1)) {
      return "    " + placeHolder;
    } else {
      return placeHolder;
    }
  };

  return (
    <InputContainer>
      {icon && !internalFocus && (!value || value.length < 1) && (
        <IconContainer className="text-input-icon">{icon}</IconContainer>
      )}
      <Input
        ref={textInput}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
        className={className}
        placeholder={getPlaceHolderText() ?? ""}
        disabled={disabled}
        onChange={handleChange}
        onClick={(event) => {
          if (onClick !== undefined) {
            onClick(event);
          }
        }}
        onKeyDown={handleKeyDown}
      />
    </InputContainer>
  );
};

export default TextInput;

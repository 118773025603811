import { createBrowserRouter } from "react-router-dom";
import Analytics from "../pages/analytics/Analytics";
import Dashboard from "../pages/dashboard/Dashboard";
import ErrorPage from "../pages/error-page/error-page";
import GroupPhotos from "../pages/group-photos/GroupPhotos";
import Groups from "../pages/groups/Groups";
import LoginPage from "../pages/login/LoginPage";
import MatchVideos from "../pages/match-videos/MatchVideos";
import NewsPage from "../pages/news/NewsPage";
import PhotoVerify from "../pages/photo-verify/PhotoVerify";
import PostReview from "../pages/post-review/PostReview";
import PrivatePhotos from "../pages/private-photos/PrivatePhotos";
import PrivateVideos from "../pages/private-videos/PrivateVideos";
import ProfilePhotos from "../pages/profile-photos/ProfilePhotos";
import Reels from "../pages/reels/Reels";
import Subscriptions from "../pages/subscriptions/Subscriptions";
import UnresolvedReports from "../pages/unresolved-reports/UnresolvedReports";
import UnverifiedPhotos from "../pages/unverified-photos/UnverifiedPhotos";
import UnverifiedVideos from "../pages/unverified-videos/UnverifiedVIdeos";
import UserActivityMap from "../pages/user-activity/UserActivityMap";
import UserPage from "../pages/user/UserPage";
import UsersPage from "../pages/users/UsersPage";
import { RequireAuth } from "./RequireAuth";
import Root from "./Root";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        ),
      },
      {
        path: "/users",
        element: (
          <RequireAuth>
            <UsersPage />
          </RequireAuth>
        ),
      },
      {
        path: "/user-activity",
        element: (
          <RequireAuth>
            <UserActivityMap />
          </RequireAuth>
        ),
      },
      {
        path: "/subscriptions",
        element: (
          <RequireAuth>
            <Subscriptions />
          </RequireAuth>
        ),
      },
      {
        path: "/unverified-photos",
        element: (
          <RequireAuth>
            <UnverifiedPhotos />
          </RequireAuth>
        ),
      },
      {
        path: "/unverified-videos",
        element: (
          <RequireAuth>
            <UnverifiedVideos />
          </RequireAuth>
        ),
      },
      {
        path: "/profile-photos",
        element: (
          <RequireAuth>
            <ProfilePhotos />
          </RequireAuth>
        ),
      },
      {
        path: "/match-videos",
        element: (
          <RequireAuth>
            <MatchVideos />
          </RequireAuth>
        ),
      },
      {
        path: "/photo-verify",
        element: (
          <RequireAuth>
            <PhotoVerify />
          </RequireAuth>
        ),
      },
      {
        path: "/group-photos",
        element: (
          <RequireAuth>
            <GroupPhotos />
          </RequireAuth>
        ),
      },
      {
        path: "/groups",
        element: (
          <RequireAuth>
            <Groups />
          </RequireAuth>
        ),
      },
      {
        path: "/post-review",
        element: (
          <RequireAuth>
            <PostReview />
          </RequireAuth>
        ),
      },
      {
        path: "/analytics",
        element: (
          <RequireAuth>
            <Analytics />
          </RequireAuth>
        ),
      },
      {
        path: "/reels",
        element: (
          <RequireAuth>
            <Reels />
          </RequireAuth>
        ),
      },
      {
        path: "/private-photos",
        element: (
          <RequireAuth>
            <PrivatePhotos />
          </RequireAuth>
        ),
      },
      {
        path: "/private-videos",
        element: (
          <RequireAuth>
            <PrivateVideos />
          </RequireAuth>
        ),
      },
      {
        path: "/unresolved-reports",
        element: (
          <RequireAuth>
            <UnresolvedReports />
          </RequireAuth>
        ),
      },
      {
        path: "/user/:id",
        element: (
          <RequireAuth>
            <UserPage />
          </RequireAuth>
        ),
      },
      {
        path: "/news",
        element: (
          <RequireAuth>
            <NewsPage />
          </RequireAuth>
        ),
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
    ],
  },
]);

export default router;

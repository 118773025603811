import { useMutation, useQuery } from "@apollo/client";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { MATCH_VIDEOS_APPROVAL } from "../../graphql/queries-gql";
import { IconButton, useTheme } from "@mui/material";
import { APPROVE_MATCH_VIDEO } from "../../graphql/mutations-gql";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import {
  PhotoActionContainer,
  PhotoContainer,
  PhotoIconContainer,
  PhotoThumbnail,
} from "../unverified-photos/styled";
import { User } from "../../graphql/graphql";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  UserIcon,
} from "@heroicons/react/24/solid";

const MatchVideos = () => {
  const { data, loading, error } = useQuery(MATCH_VIDEOS_APPROVAL);
  const theme = useTheme();
  const [approveMatchVideo] = useMutation(APPROVE_MATCH_VIDEO);
  const navigate = useNavigate();

  const users: User[] = data?.matchVideosApproval || [];

  const handleApprove = (value: boolean, userId: string) => {
    approveMatchVideo({
      variables: {
        userId,
        value,
      },
      onCompleted: (data) => {
        console.log("approveMatchVideo onCompleted", data);
      },
      onError: (error) => {
        console.log("approveMatchVideo onError", error);
      },
      refetchQueries: [MATCH_VIDEOS_APPROVAL],
    });
  };

  const showVideo = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Match videos
        </Text>
      </FlexRow>

      {loading && (
        <FlexRow>
          <Loader />
        </FlexRow>
      )}
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}

      <FlexRow colGap={10}>
        {users.map((user) => {
          return (
            <PhotoContainer key={user._id}>
              <PhotoIconContainer className="action-container">
                <IconButton
                  onClick={() => navigate(`/user/${user._id}`)}
                  sx={{
                    width: "32px",
                    height: "32px",
                    padding: "5px",
                    backgroundColor: theme.colors.gray,
                  }}
                >
                  <UserIcon width={20} />
                </IconButton>
              </PhotoIconContainer>
              <PhotoActionContainer className="action-container">
                <IconButton
                  onClick={() => handleApprove(true, user._id)}
                  sx={{
                    width: "32px",
                    height: "32px",
                    padding: "5px",
                    backgroundColor: theme.colors.green,
                  }}
                >
                  <HandThumbUpIcon width={20} />
                </IconButton>
                <IconButton
                  onClick={() => handleApprove(false, user._id)}
                  sx={{
                    width: "32px",
                    height: "32px",
                    padding: "5px",
                    backgroundColor: theme.colors.red,
                  }}
                >
                  <HandThumbDownIcon width={20} />
                </IconButton>
              </PhotoActionContainer>
              <PhotoThumbnail
                src={user.matchVideo?.thumbnailUrl ?? ""}
                onClick={() => showVideo(user.matchVideo?.url ?? "")}
              />
            </PhotoContainer>
          );
        })}
      </FlexRow>
    </FlexCol>
  );
};
export default MatchVideos;

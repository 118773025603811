import { useLazyQuery } from "@apollo/client";
import { UserIcon } from "@heroicons/react/24/solid";
import { IconButton, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import Loader from "../../components/Loader/Loader";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { PagingResult, Video } from "../../graphql/graphql";
import { PRIVATE_VIDEOS } from "../../graphql/queries-gql";

const PAGE_SIZE = 20;

const PhotoThumbnail = styled.img<{ src: string }>`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
`;
const PhotoContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;

  &:hover {
    cursor: pointer;

    .action-container {
      display: flex;
    }
  }
`;

const IconContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 5px;
  top: 5px;
  display: none;
  flex-direction: row;
  column-gap: 5px;
  z-index: 100;
  justify-content: flex-start;
`;

const PrivateVideos = () => {
  const [fetchVideos, { loading, error }] = useLazyQuery(PRIVATE_VIDEOS);
  const [page, setPage] = useState<PagingResult | undefined>(undefined);
  const [videos, setVideos] = useState<Video[]>([]);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (!error && !page) {
      fetchVideos({
        variables: {
          paging: {
            type: "page",
            pageArgs: {
              page: 1,
              limit: PAGE_SIZE,
            },
          },
        },
        onCompleted: (data) => {
          console.log("fetchVideos onCompleted", data);
          setPage(data.privateVideos.paging);
          setVideos(data.privateVideos.nodes);
        },
        onError: (error) => {
          console.log("fetchVideos onError", error);
        },
      });
    }
  }, [error, fetchVideos, page]);

  const openUrl = (src: string) => {
    //window.location.href = src;
    window.open(src, "_blank");
  };

  const showMore = () => {
    fetchVideos({
      variables: {
        paging: {
          type: "page",
          pageArgs: {
            page: page?.nextPage,
            limit: PAGE_SIZE,
          },
        },
      },
      onCompleted: (data) => {
        console.log("privateVideos onCompleted", data);
        setPage(data.privateVideos.paging);
        setVideos([...videos, ...data.privateVideos.nodes]);
      },
      onError: (error) => {
        console.log("privateVideos onError", error);
      },
    });
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Private photos
        </Text>
      </FlexRow>

      {videos && videos.length > 0 && (
        <FlexRow colGap={10}>
          {videos.map((video) => {
            return (
              <PhotoContainer key={video.uploadId}>
                <IconContainer className="action-container">
                  <IconButton
                    onClick={() => navigate(`/user/${video.uploadById}`)}
                    sx={{
                      width: "24px",
                      height: "24px",
                      padding: "5px",
                      backgroundColor: theme.colors.gray,
                    }}
                  >
                    <UserIcon width={20} />
                  </IconButton>
                </IconContainer>
                <PhotoThumbnail
                  src={video.thumbnailUrl ?? ""}
                  onClick={() => openUrl(video.url)}
                />
              </PhotoContainer>
            );
          })}
        </FlexRow>
      )}

      {page?.hasNextPage && (
        <FlexRow justifyContent="center">
          <Text onClick={() => showMore()}>Shore more videos</Text>
        </FlexRow>
      )}

      {loading && (
        <FlexRow>
          <Loader />
        </FlexRow>
      )}
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}
    </FlexCol>
  );
};
export default PrivateVideos;

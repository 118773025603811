import { IconButton, useTheme } from "@mui/material";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { useMutation, useQuery } from "@apollo/client";
import { PHOTO_VERIFICATIONS } from "../../graphql/queries-gql";
import { APPROVE_PHOTO_VERIFICATION } from "../../graphql/mutations-gql";
import { useNavigate } from "react-router-dom";
import { PhotoVerification } from "../../graphql/graphql";
import {
  PhotoActionContainer,
  PhotoContainer,
  PhotoIconContainer,
  PhotoThumbnail,
} from "../unverified-photos/styled";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import Loader from "../../components/Loader/Loader";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";

const PhotoVerify = () => {
  const { data, loading, error } = useQuery(PHOTO_VERIFICATIONS);
  const theme = useTheme();
  const [approvePhotoVerification] = useMutation(APPROVE_PHOTO_VERIFICATION);
  const navigate = useNavigate();

  const verifications: PhotoVerification[] = data?.photoVerifications || [];

  const handleApprove = (value: boolean, userId: string) => {
    approvePhotoVerification({
      variables: {
        userId,
        value,
      },
      onCompleted: (data) => {
        console.log("approvePhotoVerification onCompleted", data);
      },
      onError: (error) => {
        console.log("approvePhotoVerification onError", error);
      },
      refetchQueries: [PHOTO_VERIFICATIONS],
    });
  };

  const showImage = (src: string) => {
    //window.location.href = src;
    window.open(src, "_blank");
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Photo verification
        </Text>
      </FlexRow>

      {loading && (
        <FlexRow>
          <Loader />
        </FlexRow>
      )}
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}

      <FlexRow colGap={10}>
        {verifications &&
          verifications.map((v) => {
            return (
              <FlexRow key={v.profilePhoto?.uploadId} wrap="nowrap">
                <PhotoContainer key={v.profilePhoto?.uploadId}>
                  <PhotoIconContainer className="action-container">
                    <IconButton
                      onClick={() => navigate(`/user/${v.userId}`)}
                      sx={{
                        width: "32px",
                        height: "32px",
                        padding: "5px",
                        backgroundColor: theme.colors.gray,
                      }}
                    >
                      <UserIcon width={20} />
                    </IconButton>
                  </PhotoIconContainer>
                  <PhotoActionContainer className="action-container">
                    <IconButton
                      onClick={() => handleApprove(true, v.userId)}
                      sx={{
                        width: "32px",
                        height: "32px",
                        padding: "5px",
                        backgroundColor: theme.colors.green,
                      }}
                    >
                      <HandThumbUpIcon width={20} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleApprove(false, v.userId)}
                      sx={{
                        width: "32px",
                        height: "32px",
                        padding: "5px",
                        backgroundColor: theme.colors.red,
                      }}
                    >
                      <HandThumbDownIcon width={20} />
                    </IconButton>
                  </PhotoActionContainer>
                  <PhotoThumbnail
                    src={v.profilePhoto?.thumbnailUrl ?? ""}
                    onClick={() => showImage(v.profilePhoto?.url ?? "")}
                  />
                </PhotoContainer>
                <PhotoThumbnail
                  src={v.verifyPhoto?.thumbnailUrl ?? ""}
                  onClick={() => showImage(v.verifyPhoto?.url ?? "")}
                />
              </FlexRow>
            );
          })}
      </FlexRow>
    </FlexCol>
  );
};
export default PhotoVerify;

import { MarkerClusterer, type Marker } from "@googlemaps/markerclusterer";
import { useTheme } from "@mui/material";
import { InfoWindow, useMap } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useMemo, useState } from "react";
import Avatar from "../../components/Avatar/Avatar";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import { StyledLink } from "../../components/Styled/StyledLink";
import Text from "../../components/Styled/Text";
import { UserActivityMapData } from "../../graphql/graphql";
import { calculateAge, timeSinceString } from "../../util/helpers";
import { PoiMarker } from "./PoiMarker";

export type Poi = {
  key: string;
  location: google.maps.LatLngLiteral;
  userActivityMapData: UserActivityMapData;
};

const ClusteredPoiMarkers = (props: { pois: Poi[] }) => {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const [selectedPoiKey, setSelectedPoiKey] = useState<string | null>(null);
  const theme = useTheme();

  const selectedPoi = useMemo(
    () =>
      props.pois && selectedPoiKey
        ? props.pois.find((t) => t.key === selectedPoiKey)!
        : null,
    [props.pois, selectedPoiKey]
  );

  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  const handleMarkerClick = useCallback((poi: Poi) => {
    setSelectedPoiKey(poi.key);
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedPoiKey(null);
  }, []);

  const handleZoom = useCallback(
    (poi: Poi) => {
      map?.setCenter(poi.location);
      map?.setZoom(12);
    },
    [map]
  );

  return (
    <>
      {props.pois.map((poi) => (
        <PoiMarker
          key={poi.key}
          poi={poi}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
        />
      ))}

      {selectedPoiKey && selectedPoi && (
        <InfoWindow
          anchor={markers[selectedPoiKey]}
          onCloseClick={handleInfoWindowClose}
        >
          <FlexCol padding={0}>
            <FlexRow>
              <Avatar
                name={selectedPoi?.userActivityMapData.username ?? ""}
                url={
                  selectedPoi?.userActivityMapData.photo?.uploadComplete
                    ? selectedPoi?.userActivityMapData.photo?.thumbnailUrl
                    : ""
                }
                sx={{ width: "100px", height: "100px" }}
              />
            </FlexRow>
            <FlexRow>
              <StyledLink
                to={`/user/${selectedPoi?.userActivityMapData.userId}`}
              >
                <Text size={16} weight={600} color={"#000"}>
                  {selectedPoi?.userActivityMapData.username} {"("}
                  {calculateAge(selectedPoi?.userActivityMapData.birthDate)}
                  {")"}
                </Text>
              </StyledLink>
            </FlexRow>
            <FlexRow>
              <Text size={14} weight={400} color={"#000"}>
                {selectedPoi?.userActivityMapData.city} {" | "}{" "}
                {selectedPoi?.userActivityMapData.country}
              </Text>
            </FlexRow>
            <FlexRow>
              <Text size={12} weight={400} color={"#000"}>
                Active{" "}
                {timeSinceString(
                  new Date(selectedPoi?.userActivityMapData.lastActive)
                )}
                {" | "}
                Created{" "}
                {timeSinceString(
                  new Date(selectedPoi?.userActivityMapData.created)
                )}
              </Text>
            </FlexRow>
            <FlexRow>
              <StyledLink
                to=""
                onClick={() => selectedPoi && handleZoom(selectedPoi)}
              >
                Zoom
              </StyledLink>
              {" | "}
              <StyledLink
                to=""
                onClick={() => {
                  map?.setZoom(2);
                  map?.setCenter({
                    lat: 35.613266732528366,
                    lng: 9.880013000000014,
                  });
                  setSelectedPoiKey(null);
                }}
              >
                Reset zoom
              </StyledLink>
            </FlexRow>
          </FlexCol>
        </InfoWindow>
      )}
    </>
  );
};
export default ClusteredPoiMarkers;

import { useMutation, useQuery } from "@apollo/client";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { IconButton, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import Loader from "../../components/Loader/Loader";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { Reel, ReelsApprovalResult } from "../../graphql/graphql";
import { APPROVE_REEL } from "../../graphql/mutations-gql";
import { REELS_APPROVAL } from "../../graphql/queries-gql";
import {
  PhotoActionContainer,
  PhotoContainer,
  PhotoIconContainer,
  PhotoThumbnail,
} from "../unverified-photos/styled";

const Reels = () => {
  const { data, loading, error } = useQuery<{
    reelsApproval: ReelsApprovalResult;
  }>(REELS_APPROVAL, {
    onCompleted: (data) => {
      console.log("fetchReels onCompleted", JSON.stringify(data, null, 2));
    },
  });
  const theme = useTheme();
  const [approveReel] = useMutation(APPROVE_REEL);
  const navigate = useNavigate();

  const reels: Reel[] = data?.reelsApproval.reels || [];
  const latestReels: Reel[] = data?.reelsApproval.latestReels || [];

  const handleApprove = (value: boolean, reelId: string) => {
    approveReel({
      variables: {
        reelId,
        value,
      },
      onCompleted: (data) => {
        console.log("approveReel onCompleted", data);
      },
      onError: (error) => {
        console.log("approveReel onError", error);
      },
      refetchQueries: [REELS_APPROVAL],
    });
  };

  const showVideo = (url: string) => {
    window.open(url, "_blank");
  };

  if (loading) {
    return (
      <FlexCol>
        <FlexRow>
          <Loader />
        </FlexRow>
      </FlexCol>
    );
  }
  if (error) {
    return (
      <FlexCol>
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      </FlexCol>
    );
  }

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Reels to approve
        </Text>
      </FlexRow>

      <FlexCol rowGap={10}>
        {reels.map((reel) => {
          return (
            <FlexCol>
              <PhotoContainer size={360} key={reel._id}>
                <PhotoIconContainer className="action-container">
                  <IconButton
                    onClick={() => navigate(`/user/${reel.byUserId}`)}
                    sx={{
                      width: "32px",
                      height: "32px",
                      padding: "5px",
                      backgroundColor: theme.colors.gray,
                    }}
                  >
                    <UserIcon width={20} />
                  </IconButton>
                </PhotoIconContainer>
                <PhotoActionContainer className="action-container">
                  <IconButton
                    onClick={() => handleApprove(true, reel._id)}
                    sx={{
                      width: "32px",
                      height: "32px",
                      padding: "5px",
                      backgroundColor: theme.colors.green,
                    }}
                  >
                    <HandThumbUpIcon width={20} />
                  </IconButton>
                  <IconButton
                    onClick={() => handleApprove(false, reel._id)}
                    sx={{
                      width: "32px",
                      height: "32px",
                      padding: "5px",
                      backgroundColor: theme.colors.red,
                    }}
                  >
                    <HandThumbDownIcon width={20} />
                  </IconButton>
                </PhotoActionContainer>
                <PhotoThumbnail
                  size={360}
                  src={reel.thumbnailUrl ?? ""}
                  onClick={() => showVideo(reel.url ?? "")}
                />
              </PhotoContainer>
              <Text>{reel.description}</Text>
            </FlexCol>
          );
        })}
      </FlexCol>

      <FlexRow>
        <Text size={18} weight={600}>
          Latest reels
        </Text>
      </FlexRow>

      <FlexRow rowGap={10}>
        {latestReels.map((reel) => {
          return (
            <FlexCol key={reel._id}>
              <PhotoContainer size={120} key={reel._id}>
                <PhotoIconContainer className="action-container">
                  <IconButton
                    onClick={() => navigate(`/user/${reel.byUserId}`)}
                    sx={{
                      width: "32px",
                      height: "32px",
                      padding: "5px",
                      backgroundColor: theme.colors.gray,
                    }}
                  >
                    <UserIcon width={20} />
                  </IconButton>
                </PhotoIconContainer>
                <PhotoThumbnail
                  size={120}
                  src={reel.thumbnailUrl ?? ""}
                  onClick={() => showVideo(reel.url ?? "")}
                />
              </PhotoContainer>
              <Text>{reel.description}</Text>
            </FlexCol>
          );
        })}
      </FlexRow>
    </FlexCol>
  );
};
export default Reels;

import { useMutation, useQuery } from "@apollo/client";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { IconButton, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import Loader from "../../components/Loader/Loader";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { Video } from "../../graphql/graphql";
import { APPROVE_VIDEO } from "../../graphql/mutations-gql";
import { UNVERIFIED_VIDEOS } from "../../graphql/queries-gql";
import {
  PhotoActionContainer,
  PhotoContainer,
  PhotoIconContainer,
  PhotoThumbnail,
} from "./styled";

const PAGE_SIZE = 20;

const UnverifiedVideos = () => {
  const { loading, error } = useQuery(UNVERIFIED_VIDEOS, {
    fetchPolicy: "network-only",
    variables: {
      paging: {
        type: "page",
        pageArgs: {
          page: 1,
          limit: PAGE_SIZE,
        },
      },
    },
    onCompleted: (data) => {
      console.log("fetchVideos onCompleted", data);
      setVideos(data.unverifiedVideos.nodes);
    },
    onError: (error) => {
      console.log("fetchVideos onError", error);
    },
  });
  const [videos, setVideos] = useState<Video[]>([]);
  const theme = useTheme();
  const [approveVideo] = useMutation(APPROVE_VIDEO);
  const navigate = useNavigate();

  const showImage = (src: string) => {
    //window.location.href = src;
    window.open(src, "_blank");
  };

  const handleApprove = (value: boolean, uploadId: string) => {
    approveVideo({
      variables: {
        uploadId,
        value,
      },
      onCompleted: (data) => {
        // Completion means we should remove the photo.
        const newVideos = videos.filter((video) => video.uploadId !== uploadId);
        console.log("approveVideo onCompleted", data);
        setVideos(newVideos);
      },
      onError: (error) => {
        console.log("approveVideo onError", error);
      },
    });
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Unverified videos
        </Text>
      </FlexRow>

      {videos && videos.length > 0 && (
        <FlexRow colGap={10}>
          {videos.map((video) => {
            return (
              <FlexCol key={video.uploadId}>
                <PhotoContainer size={360}>
                  <PhotoIconContainer className="action-container">
                    <IconButton
                      onClick={() => navigate(`/user/${video.uploadById}`)}
                      sx={{
                        width: "48px",
                        height: "48px",
                        padding: "5px",
                        backgroundColor: theme.colors.gray,
                      }}
                    >
                      <UserIcon width={20} />
                    </IconButton>
                  </PhotoIconContainer>
                  <PhotoActionContainer className="action-container">
                    <IconButton
                      onClick={() => handleApprove(true, video.uploadId)}
                      sx={{
                        width: "48px",
                        height: "48px",
                        padding: "5px",
                        backgroundColor: theme.colors.green,
                      }}
                    >
                      <HandThumbUpIcon width={20} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleApprove(false, video.uploadId)}
                      sx={{
                        width: "48px",
                        height: "48px",
                        padding: "5px",
                        backgroundColor: theme.colors.red,
                      }}
                    >
                      <HandThumbDownIcon width={20} />
                    </IconButton>
                  </PhotoActionContainer>
                  <PhotoThumbnail
                    size={300}
                    src={video.thumbnailUrl ?? ""}
                    onClick={() => showImage(video.url)}
                  />
                </PhotoContainer>
              </FlexCol>
            );
          })}
        </FlexRow>
      )}

      {loading && (
        <FlexRow>
          <Loader />
        </FlexRow>
      )}
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}
    </FlexCol>
  );
};
export default UnverifiedVideos;

import { gql } from "@apollo/client";

export const GOOGLE_LOGIN = gql`
  mutation googleLogin($token: String!, $captcha: String!) {
    googleLogin(token: $token, captcha: $captcha) {
      authenticated
      token
    }
  }
`;

export const APPROVE_PHOTO = gql`
  mutation approvePhoto($uploadId: String!, $value: Boolean!) {
    approvePhoto(uploadId: $uploadId, value: $value)
  }
`;

export const APPROVE_VIDEO = gql`
  mutation approveVideo($uploadId: String!, $value: Boolean!) {
    approveVideo(uploadId: $uploadId, value: $value)
  }
`;

export const SET_BAN = gql`
  mutation setBan($_id: String!, $value: Boolean!, $until: DateTime) {
    setBan(_id: $_id, value: $value, until: $until)
  }
`;

export const CALC_POPULARITY = gql`
  mutation calcPopularity {
    calcPopularity
  }
`;

export const FIX_PHOTOS = gql`
  mutation fixPhotos {
    fixPhotos
  }
`;

export const APPROVE_PROFILE_PHOTO = gql`
  mutation approveProfilePhoto($userId: String!, $value: Boolean!) {
    approveProfilePhoto(userId: $userId, value: $value)
  }
`;

export const APPROVE_MATCH_VIDEO = gql`
  mutation approveMatchVideo($userId: String!, $value: Boolean!) {
    approveMatchVideo(userId: $userId, value: $value)
  }
`;

export const APPROVE_REEL = gql`
  mutation approveReel($reelId: String!, $value: Boolean!) {
    approveReel(reelId: $reelId, value: $value)
  }
`;

export const APPROVE_PHOTO_VERIFICATION = gql`
  mutation approvePhotoVerification($userId: String!, $value: Boolean!) {
    approvePhotoVerification(userId: $userId, value: $value)
  }
`;

export const REVIEW_POST = gql`
  mutation reviewPost($postId: String!, $reviewStatus: PostReviewStatus!) {
    reviewPost(postId: $postId, reviewStatus: $reviewStatus)
  }
`;

export const APPROVE_GROUP_PHOTO = gql`
  mutation approveGroupPhoto($groupId: String!, $value: Boolean!) {
    approveGroupPhoto(groupId: $groupId, value: $value)
  }
`;

export const APPROVE_GROUP = gql`
  mutation approveGroup($groupId: String!, $value: Boolean!) {
    approveGroup(groupId: $groupId, value: $value)
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`;

import { useMutation, useQuery } from "@apollo/client";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { IconButton, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import Loader from "../../components/Loader/Loader";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { Photo } from "../../graphql/graphql";
import { APPROVE_PHOTO } from "../../graphql/mutations-gql";
import { UNVERIFIED_PHOTOS } from "../../graphql/queries-gql";
import {
  PhotoActionContainer,
  PhotoContainer,
  PhotoIconContainer,
  PhotoThumbnail,
} from "./styled";

const PAGE_SIZE = 50;

const UnverifiedPhotos = () => {
  const { loading, error } = useQuery(UNVERIFIED_PHOTOS, {
    fetchPolicy: "network-only",
    variables: {
      paging: {
        type: "page",
        pageArgs: {
          page: 1,
          limit: PAGE_SIZE,
        },
      },
    },
    onCompleted: (data) => {
      console.log("fetchPhotos onCompleted", data);
      setPhotos(data.unverifiedPhotos.nodes);
    },
    onError: (error) => {
      console.log("fetchPhotos onError", error);
    },
  });

  const [photos, setPhotos] = useState<Photo[]>([]);
  const theme = useTheme();
  const [approvePhoto] = useMutation(APPROVE_PHOTO);
  const navigate = useNavigate();

  const showImage = (src: string) => {
    //window.location.href = src;
    window.open(src, "_blank");
  };

  // const showMore = () => {
  //   fetchPhotos({
  //     variables: {
  //       paging: {
  //         type: "page",
  //         pageArgs: {
  //           page: page?.nextPage,
  //           limit: PAGE_SIZE,
  //         },
  //       },
  //     },
  //     onCompleted: (data) => {
  //       console.log("fetchPhotos onCompleted", data);
  //       setPage(data.unverifiedPhotos.paging);
  //       setPhotos([...photos, ...data.unverifiedPhotos.nodes]);
  //     },
  //     onError: (error) => {
  //       console.log("fetchPhotos onError", error);
  //     },
  //   });
  // };

  const handleApprove = (value: boolean, uploadId: string) => {
    approvePhoto({
      variables: {
        uploadId,
        value,
      },
      onCompleted: (data) => {
        // Completion means we should remove the photo.
        const newPhotos = photos.filter((photo) => photo.uploadId !== uploadId);
        setPhotos(newPhotos);
      },
      onError: (error) => {
        console.log("approvePhoto onError", error);
      },
    });
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Unverified photos
        </Text>
      </FlexRow>

      {photos && photos.length > 0 && (
        <FlexRow colGap={10}>
          {photos.map((photo) => {
            return (
              <FlexCol key={photo.uploadId}>
                <PhotoContainer size={300}>
                  <PhotoIconContainer className="action-container">
                    <IconButton
                      onClick={() => navigate(`/user/${photo.uploadById}`)}
                      sx={{
                        width: "48px",
                        height: "48px",
                        padding: "5px",
                        backgroundColor: theme.colors.gray,
                      }}
                    >
                      <UserIcon width={20} />
                    </IconButton>
                  </PhotoIconContainer>
                  <PhotoActionContainer className="action-container">
                    <IconButton
                      onClick={() => handleApprove(true, photo.uploadId)}
                      sx={{
                        width: "48px",
                        height: "48px",
                        padding: "5px",
                        backgroundColor: theme.colors.green,
                      }}
                    >
                      <HandThumbUpIcon width={20} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleApprove(false, photo.uploadId)}
                      sx={{
                        width: "48px",
                        height: "48px",
                        padding: "5px",
                        backgroundColor: theme.colors.red,
                      }}
                    >
                      <HandThumbDownIcon width={20} />
                    </IconButton>
                  </PhotoActionContainer>
                  <PhotoThumbnail
                    size={300}
                    src={photo.url}
                    onClick={() => showImage(photo.url)}
                  />
                </PhotoContainer>
              </FlexCol>
            );
          })}
        </FlexRow>
      )}

      {loading && (
        <FlexRow>
          <Loader />
        </FlexRow>
      )}
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}
    </FlexCol>
  );
};
export default UnverifiedPhotos;

import { IconButton, useTheme } from "@mui/material";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import Loader from "../../components/Loader/Loader";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import {
  PhotoActionContainer,
  PhotoContainer,
  PhotoThumbnail,
} from "../unverified-photos/styled";
import { useNavigate } from "react-router-dom";
import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/solid";
import { GROUP_PHOTOS } from "../../graphql/queries-gql";
import { useMutation, useQuery } from "@apollo/client";
import { Group } from "../../graphql/graphql";
import { APPROVE_GROUP_PHOTO } from "../../graphql/mutations-gql";

const GroupPhotos = () => {
  const theme = useTheme();
  const { data, loading, error } = useQuery(GROUP_PHOTOS);
  const [approveGroupPhoto] = useMutation(APPROVE_GROUP_PHOTO);

  const showImage = (src: string) => {
    //window.location.href = src;
    window.open(src, "_blank");
  };

  const handleApprove = (value: boolean, groupId: string) => {
    approveGroupPhoto({
      variables: {
        groupId,
        value,
      },
      onCompleted: (data) => {
        console.log("approveGroupPhoto onCompleted", data);
      },
      onError: (error) => {
        console.log("approveGroupPhoto onError", error);
      },
      refetchQueries: [GROUP_PHOTOS],
    });
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Group photos
        </Text>
      </FlexRow>

      {loading && (
        <FlexRow>
          <Loader />
        </FlexRow>
      )}
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}

      <FlexRow colGap={10}>
        {data?.groupPhotos.map((group: Group) => {
          return (
            <PhotoContainer key={group.photo?.uploadId}>
              <PhotoActionContainer className="action-container">
                <IconButton
                  onClick={() => handleApprove(true, group._id)}
                  sx={{
                    width: "24px",
                    height: "24px",
                    padding: "5px",
                    backgroundColor: theme.colors.green,
                  }}
                >
                  <HandThumbUpIcon width={20} />
                </IconButton>
                <IconButton
                  onClick={() => handleApprove(false, group._id)}
                  sx={{
                    width: "24px",
                    height: "24px",
                    padding: "5px",
                    backgroundColor: theme.colors.red,
                  }}
                >
                  <HandThumbDownIcon width={20} />
                </IconButton>
              </PhotoActionContainer>
              <PhotoThumbnail
                src={group.photo?.thumbnailUrl ?? ""}
                onClick={() => showImage(group.photo?.url ?? "")}
              />
            </PhotoContainer>
          );
        })}
      </FlexRow>
    </FlexCol>
  );
};
export default GroupPhotos;

import { useMutation, useQuery } from "@apollo/client";
import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import { LoginResult } from "../../graphql/graphql";
import { GOOGLE_LOGIN } from "../../graphql/mutations-gql";
import { HELLO } from "../../graphql/queries-gql";
import { useAuthStore } from "../../hooks/useAuth";
import GoogleButton from "./GoogleButton";

const PageContainer = styled.div`
  height: 100%;
  padding-top: 50px;

  .App {
    text-align: center;
  }

  .logo-text {
    height: 200px;
  }

  .App-logo {
    height: 20vmin;
    pointer-events: none;
  }

  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }
`;

const LoginBox = styled.div`
  width: 320px;
  border-radius: 15px;
`;

const LoginPage = () => {
  const setAuthenticated = useAuthStore.use.setAuthenticated();
  const [googleLoginMutation, { error: googleError }] = useMutation<{
    googleLogin: LoginResult;
  }>(GOOGLE_LOGIN);
  const [socialLoginError, setSocialLoginError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState<string | null>(null);
  const { data } = useQuery(HELLO);

  const onCaptchaChange = (value: any) => {
    console.log("captcha change", value);
    setCaptcha(value);
  };

  const loginWithGoogleToken = (access_token: string) => {
    // Try a login with google. If no user exists we show the google signup dialog that lets the user select
    // some necessary fields before signup can be completed. Signup should automatically login the user.
    googleLoginMutation({
      variables: {
        token: access_token,
        captcha: captcha,
      },
      onCompleted: (data) => {
        console.log("googleLogin onCompleted", data);
        if (data.googleLogin.authenticated && data.googleLogin.token) {
          localStorage.setItem("auth_token", data.googleLogin.token);
          setAuthenticated(true);
          navigate("/");
        }
      },
      onError: (error) => {
        console.log("googleLogin onError", error);
      },
    });
  };

  const googleLogin = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);

      // access_token
      // token_type
      if (tokenResponse) {
        loginWithGoogleToken(tokenResponse.access_token);
      } else {
        setSocialLoginError("No valid response received from Google.");
      }
    },
  });

  return (
    <PageContainer>
      <FlexCol justifyContent="center" alignItems="center" rowGap={50}>
        <FlexRow>
          <img width="100px" src={"/logo-small.png"} alt="logo" />
          <img width="250px" src={"/logo-text-white.png"} alt="logo" />
        </FlexRow>
        <LoginBox>
          <FlexCol>
            <FlexRow justifyContent="center">
              <ReCAPTCHA
                className="recaptcha-class"
                theme={"dark"}
                sitekey="6Lfzc-4SAAAAAByyUavMBhn0QmfShVQunuiCupto"
                onChange={(value: any) => onCaptchaChange(value)}
              />
            </FlexRow>
            <FlexRow justifyContent="center">
              <GoogleButton onClick={() => googleLogin()} />
            </FlexRow>
            {googleError && (
              <FlexRow justifyContent="center">
                <ErrorAlert error={googleError} />
              </FlexRow>
            )}
            {socialLoginError && (
              <FlexRow justifyContent="center">
                <ErrorAlert error={socialLoginError} />
              </FlexRow>
            )}
          </FlexCol>
        </LoginBox>
      </FlexCol>
    </PageContainer>
  );
};
export default LoginPage;

import { useTheme } from "@mui/material";
import { useRouteError } from "react-router-dom";
import styled from "styled-components";
import { StyledLink } from "../../components/Styled/StyledLink";
import Text from "../../components/Styled/Text";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  //width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
`;

export default function ErrorPage() {
  const error: any = useRouteError();
  const theme = useTheme();

  console.error(error);

  const clearCaches = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <PageContainer>
      <Content>
        <Text size={100} weight={600} color={theme.colors.primary}>
          Oops!
        </Text>
        <Text size={30} weight={600} color={theme.colors.gray}>
          Sorry, an unexpected error has occurred.
        </Text>

        <Text size={24} color={theme.colors.gray}>
          {error.statusText || error.message}
        </Text>

        <StyledLink to="/">Back to start</StyledLink>
        <StyledLink to="" onClick={() => clearCaches()}>
          Clear caches
        </StyledLink>
      </Content>
    </PageContainer>
  );
}

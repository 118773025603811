import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export enum AlcoholHabit {
  HateAlcohol = 'HATE_ALCOHOL',
  No = 'NO',
  Undisclosed = 'UNDISCLOSED',
  YesEveryDay = 'YES_EVERY_DAY',
  YesParties = 'YES_PARTIES',
  YesSometimes = 'YES_SOMETIMES'
}

export type AnalyticsResult = {
  __typename?: 'AnalyticsResult';
  daus?: Maybe<Array<DauItem>>;
  totalActiveUsersLast30days?: Maybe<Scalars['Int']['output']>;
  totalActiveUsersLast30daysCreatedMoreThan90daysAgo?: Maybe<Scalars['Int']['output']>;
  totalFemaleUsers?: Maybe<Scalars['Int']['output']>;
  totalMaleUsers?: Maybe<Scalars['Int']['output']>;
  totalNewUsersLast30days?: Maybe<Scalars['Int']['output']>;
  totalOtherGenderUsers?: Maybe<Scalars['Int']['output']>;
  totalPayingUsers?: Maybe<Scalars['Int']['output']>;
  totalPayingUsersLast30days?: Maybe<Scalars['Int']['output']>;
  totalUsers?: Maybe<Array<TotalUsersItem>>;
  totalUsersByCountry?: Maybe<Array<TotalUsersCountryItem>>;
  totalUsersLoginApple?: Maybe<Scalars['Int']['output']>;
  totalUsersLoginEmail?: Maybe<Scalars['Int']['output']>;
  totalUsersLoginFacebook?: Maybe<Scalars['Int']['output']>;
  totalUsersLoginGoogle?: Maybe<Scalars['Int']['output']>;
  totalUsersPlatformAndroid?: Maybe<Scalars['Int']['output']>;
  totalUsersPlatformIos?: Maybe<Scalars['Int']['output']>;
  totalUsersPlatformWeb?: Maybe<Scalars['Int']['output']>;
  totalUsersWithPremium?: Maybe<Scalars['Int']['output']>;
  totalVerifiedUsers?: Maybe<Scalars['Int']['output']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  blurhash?: Maybe<Scalars['String']['output']>;
  contentType: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  expired: Scalars['Boolean']['output'];
  thumbnailUrl: Scalars['String']['output'];
  uploadComplete: Scalars['Boolean']['output'];
  uploadId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum Beard {
  Full = 'FULL',
  Goatee = 'GOATEE',
  Mustache = 'MUSTACHE',
  No = 'NO',
  Short = 'SHORT',
  Undisclosed = 'UNDISCLOSED'
}

export type CursorQueryArgs = {
  cursor: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
};

export type DashboardData = {
  __typename?: 'DashboardData';
  dailyActiveUsers: Scalars['Int']['output'];
  monthlyActiveUsers: Scalars['Int']['output'];
  totalBannedUsers: Scalars['Int']['output'];
  totalGroups: Scalars['Int']['output'];
  totalInvites: Scalars['Int']['output'];
  totalPrivatePhotos: Scalars['Int']['output'];
  totalUnresolvedReports: Scalars['Int']['output'];
  totalUnverifiedPhotos: Scalars['Int']['output'];
  totalUnverifiedUsers: Scalars['Int']['output'];
  totalUsers: Scalars['Int']['output'];
  totalUsersWithApprovedPhoto: Scalars['Int']['output'];
};

export type DauItem = {
  __typename?: 'DauItem';
  date: Scalars['DateTime']['output'];
  mau: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
};

export enum Etnicity {
  AfricanAmerican = 'AFRICAN_AMERICAN',
  AmericanIndian = 'AMERICAN_INDIAN',
  Asian = 'ASIAN',
  Hispanic = 'HISPANIC',
  Undisclosed = 'UNDISCLOSED',
  White = 'WHITE'
}

export enum EyeColor {
  Amber = 'AMBER',
  Blue = 'BLUE',
  Brown = 'BROWN',
  Gray = 'GRAY',
  Green = 'GREEN',
  Hazel = 'HAZEL',
  Mixed = 'MIXED',
  Other = 'OTHER',
  Undisclosed = 'UNDISCLOSED'
}

export type FileUploadArgs = {
  contentLength: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export enum Gender {
  Man = 'MAN',
  NonBinary = 'NON_BINARY',
  Transgender = 'TRANSGENDER',
  Undisclosed = 'UNDISCLOSED',
  Woman = 'WOMAN'
}

export type Geo = {
  __typename?: 'Geo';
  _id: Scalars['String']['output'];
  coordinates?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GeoInput = {
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type GeocodeResult = {
  __typename?: 'GeocodeResult';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
};

export type Group = {
  __typename?: 'Group';
  _id: Scalars['String']['output'];
  about: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  genderLimited: Array<Scalars['String']['output']>;
  maxAgeLimited: Scalars['Int']['output'];
  memberCommentsAllowed: Scalars['Boolean']['output'];
  memberPostsAllowed: Scalars['Boolean']['output'];
  memberVisibility: MemberVisibility;
  minAgeLimited: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Photo>;
  privacy: GroupPrivacy;
  rules: Scalars['String']['output'];
  visibility: GroupVisibility;
};

export enum GroupPrivacy {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum GroupStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Review = 'REVIEW'
}

export enum GroupVisibility {
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE'
}

export enum HairColor {
  Black = 'BLACK',
  Blonde = 'BLONDE',
  Brown = 'BROWN',
  Colored = 'COLORED',
  Gray = 'GRAY',
  Red = 'RED',
  Shaved = 'SHAVED',
  Undisclosed = 'UNDISCLOSED'
}

export enum HereTo {
  Chat = 'CHAT',
  Date = 'DATE',
  Friendship = 'FRIENDSHIP',
  OneNight = 'ONE_NIGHT',
  Relationship = 'RELATIONSHIP'
}

export enum Interest {
  Beaches = 'BEACHES',
  Business = 'BUSINESS',
  Cars = 'CARS',
  Cats = 'CATS',
  Chill = 'CHILL',
  Clothes = 'CLOTHES',
  Coffee = 'COFFEE',
  Comedies = 'COMEDIES',
  Cooking = 'COOKING',
  CultureAndBooks = 'CULTURE_AND_BOOKS',
  Diving = 'DIVING',
  Dogs = 'DOGS',
  Driving = 'DRIVING',
  FashionAndBeauty = 'FASHION_AND_BEAUTY',
  FoodAndDrink = 'FOOD_AND_DRINK',
  Football = 'FOOTBALL',
  Friendship = 'FRIENDSHIP',
  Gaming = 'GAMING',
  Gym = 'GYM',
  Hobbies = 'HOBBIES',
  Horses = 'HORSES',
  IceCream = 'ICE_CREAM',
  Laughing = 'LAUGHING',
  Love = 'LOVE',
  MeetPeople = 'MEET_PEOPLE',
  MotorBikes = 'MOTOR_BIKES',
  MoviesAndSeries = 'MOVIES_AND_SERIES',
  Music = 'MUSIC',
  Painting = 'PAINTING',
  Party = 'PARTY',
  RoadTrips = 'ROAD_TRIPS',
  RockMusic = 'ROCK_MUSIC',
  Running = 'RUNNING',
  Sex = 'SEX',
  Shoes = 'SHOES',
  Skateboard = 'SKATEBOARD',
  Skiing = 'SKIING',
  Snowboard = 'SNOWBOARD',
  Sport = 'SPORT',
  StockMarket = 'STOCK_MARKET',
  Summer = 'SUMMER',
  Surfing = 'SURFING',
  Tea = 'TEA',
  Travel = 'TRAVEL',
  Walking = 'WALKING',
  WeekendTrips = 'WEEKEND_TRIPS',
  Winter = 'WINTER',
  WorkOut = 'WORK_OUT'
}

export enum KidsStatus {
  NoMaybeLater = 'NO_MAYBE_LATER',
  NoNever = 'NO_NEVER',
  Undisclosed = 'UNDISCLOSED',
  YesAdults = 'YES_ADULTS',
  YesFulltime = 'YES_FULLTIME',
  YesParttime = 'YES_PARTTIME',
  YesWantMore = 'YES_WANT_MORE'
}

export enum Living {
  Apartment = 'APARTMENT',
  Hotel = 'HOTEL',
  House = 'HOUSE',
  Undisclosed = 'UNDISCLOSED',
  WithParents = 'WITH_PARENTS'
}

export enum Language {
  Arabic = 'ARABIC',
  Bulgarian = 'BULGARIAN',
  Catalan = 'CATALAN',
  Chinese = 'CHINESE',
  Croatian = 'CROATIAN',
  Czech = 'CZECH',
  Danish = 'DANISH',
  Dutch = 'DUTCH',
  English = 'ENGLISH',
  Estonian = 'ESTONIAN',
  Filipino = 'FILIPINO',
  Finnish = 'FINNISH',
  French = 'FRENCH',
  German = 'GERMAN',
  Greek = 'GREEK',
  Hindi = 'HINDI',
  Hungarian = 'HUNGARIAN',
  Indonesian = 'INDONESIAN',
  Italian = 'ITALIAN',
  Japanese = 'JAPANESE',
  Khmer = 'KHMER',
  Korean = 'KOREAN',
  Latvian = 'LATVIAN',
  Lithuanian = 'LITHUANIAN',
  Malay = 'MALAY',
  Norwegian = 'NORWEGIAN',
  Polish = 'POLISH',
  Portuguese = 'PORTUGUESE',
  Romanian = 'ROMANIAN',
  Russian = 'RUSSIAN',
  Slovak = 'SLOVAK',
  Spanish = 'SPANISH',
  Swedish = 'SWEDISH',
  Thai = 'THAI',
  Turkish = 'TURKISH',
  Ukranian = 'UKRANIAN',
  Vietnamese = 'VIETNAMESE'
}

export type LanguageString = {
  __typename?: 'LanguageString';
  lang: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Location = {
  __typename?: 'Location';
  _id: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  geo?: Maybe<Geo>;
};

export type LocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<GeoInput>;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  authenticated: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export enum MeetType {
  Coffee = 'Coffee',
  Date = 'Date',
  Dinner = 'Dinner',
  Hangout = 'Hangout',
  Hookup = 'Hookup',
  OneNightStand = 'OneNightStand',
  Party = 'Party',
  Sport = 'Sport'
}

export enum MeetWhen {
  NextWeek = 'NextWeek',
  ThisWeek = 'ThisWeek',
  ThisWeekend = 'ThisWeekend',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Within30Days = 'Within30Days'
}

export enum MemberVisibility {
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE'
}

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']['output']>;
  approveGroup?: Maybe<Scalars['String']['output']>;
  approveGroupPhoto?: Maybe<Scalars['String']['output']>;
  approveMatchVideo?: Maybe<Scalars['String']['output']>;
  approvePhoto?: Maybe<Scalars['String']['output']>;
  approvePhotoVerification?: Maybe<Scalars['String']['output']>;
  approveProfilePhoto?: Maybe<Scalars['String']['output']>;
  approveReel?: Maybe<Scalars['String']['output']>;
  approveVideo?: Maybe<Scalars['String']['output']>;
  deleteUser?: Maybe<Scalars['String']['output']>;
  googleLogin?: Maybe<LoginResult>;
  reviewPost?: Maybe<Scalars['String']['output']>;
  setBan?: Maybe<Scalars['String']['output']>;
};


export type MutationApproveGroupArgs = {
  groupId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationApproveGroupPhotoArgs = {
  groupId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationApproveMatchVideoArgs = {
  userId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationApprovePhotoArgs = {
  uploadId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationApprovePhotoVerificationArgs = {
  userId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationApproveProfilePhotoArgs = {
  userId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationApproveReelArgs = {
  reelId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationApproveVideoArgs = {
  uploadId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationGoogleLoginArgs = {
  captcha: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationReviewPostArgs = {
  postId: Scalars['String']['input'];
  reviewStatus: PostReviewStatus;
};


export type MutationSetBanArgs = {
  _id: Scalars['String']['input'];
  until?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['Boolean']['input'];
};

export type OffsetQueryArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PageQueryArgs = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type PagedPhotos = {
  __typename?: 'PagedPhotos';
  nodes: Array<Photo>;
  paging: PagingResult;
};

export type PagedUsers = {
  __typename?: 'PagedUsers';
  nodes: Array<User>;
  paging: PagingResult;
};

export type PagedVideos = {
  __typename?: 'PagedVideos';
  nodes: Array<Video>;
  paging: PagingResult;
};

export type PagingArgs = {
  cursorArgs?: InputMaybe<CursorQueryArgs>;
  offsetArgs?: InputMaybe<OffsetQueryArgs>;
  pageArgs?: InputMaybe<PageQueryArgs>;
  type: Scalars['String']['input'];
};

export type PagingResult = {
  __typename?: 'PagingResult';
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export enum PersonalityTrait {
  Adventurous = 'Adventurous',
  Ambitious = 'Ambitious',
  Caring = 'Caring',
  Charismatic = 'Charismatic',
  Charming = 'Charming',
  Clever = 'Clever',
  Considerate = 'Considerate',
  Courageous = 'Courageous',
  Discreet = 'Discreet',
  Dramatic = 'Dramatic',
  Educated = 'Educated',
  Elegant = 'Elegant',
  Empathetic = 'Empathetic',
  Energetic = 'Energetic',
  Enthusiastic = 'Enthusiastic',
  Esthetic = 'Esthetic',
  Fair = 'Fair',
  Faithful = 'Faithful',
  Flexible = 'Flexible',
  Forgiving = 'Forgiving',
  Friendly = 'Friendly',
  Funny = 'Funny',
  Generous = 'Generous',
  Helpful = 'Helpful',
  Heroic = 'Heroic',
  Honest = 'Honest',
  Humble = 'Humble',
  Independent = 'Independent',
  Intelligent = 'Intelligent',
  Kind = 'Kind',
  Leader = 'Leader',
  Lovable = 'Lovable',
  Loving = 'Loving',
  Masculine = 'Masculine',
  Mature = 'Mature',
  Modest = 'Modest',
  Open = 'Open',
  Optimistic = 'Optimistic',
  Organized = 'Organized',
  Outgoing = 'Outgoing',
  Passionate = 'Passionate',
  Patient = 'Patient',
  Peaceful = 'Peaceful',
  Perfectionist = 'Perfectionist',
  Playful = 'Playful',
  Popular = 'Popular',
  Precise = 'Precise',
  Protective = 'Protective',
  Rational = 'Rational',
  Realistic = 'Realistic',
  Relaxed = 'Relaxed',
  Reliable = 'Reliable',
  Responsible = 'Responsible',
  Romantic = 'Romantic',
  Selfless = 'Selfless',
  Sensitive = 'Sensitive',
  Sentimental = 'Sentimental',
  Serious = 'Serious',
  Sharing = 'Sharing',
  Simple = 'Simple',
  Sociable = 'Sociable',
  Sophisticated = 'Sophisticated',
  Spontaneous = 'Spontaneous',
  Sweet = 'Sweet',
  Sympathetic = 'Sympathetic',
  Tasteful = 'Tasteful',
  Thoughtful = 'Thoughtful',
  Tolerant = 'Tolerant',
  Trusting = 'Trusting',
  Trustworthy = 'Trustworthy',
  Understanding = 'Understanding',
  Warm = 'Warm',
  Warmhearted = 'Warmhearted',
  Wise = 'Wise',
  Witty = 'Witty',
  Youthful = 'Youthful'
}

export type Photo = {
  __typename?: 'Photo';
  _id: Scalars['String']['output'];
  approved: Scalars['Boolean']['output'];
  blurhash?: Maybe<Scalars['String']['output']>;
  isPrivate: Scalars['Boolean']['output'];
  thumbnailUrl: Scalars['String']['output'];
  uploadById?: Maybe<Scalars['String']['output']>;
  uploadComplete: Scalars['Boolean']['output'];
  uploadId: Scalars['String']['output'];
  url: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type PhotoUploadArgs = {
  contentLength: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type PhotoUploadResult = {
  __typename?: 'PhotoUploadResult';
  uploadId: Scalars['String']['output'];
  uploadPostInfo: UploadPostInfo;
};

export type PhotoVerification = {
  __typename?: 'PhotoVerification';
  profilePhoto: Photo;
  userId: Scalars['String']['output'];
  verifyPhoto: Photo;
};

export type PlaceAutocomplete = {
  __typename?: 'PlaceAutocomplete';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  geo?: Maybe<Geo>;
};

export type Post = {
  __typename?: 'Post';
  _id: Scalars['String']['output'];
  attachments?: Maybe<Array<Attachment>>;
  audience: PostAudience;
  byUserId: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  reviewStatus: PostReviewStatus;
  text?: Maybe<Scalars['String']['output']>;
};

export enum PostAudience {
  Followers = 'FOLLOWERS',
  Friends = 'FRIENDS',
  Group = 'GROUP',
  Matches = 'MATCHES',
  Public = 'PUBLIC'
}

export enum PostReviewStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type Profile = {
  __typename?: 'Profile';
  _id: Scalars['String']['output'];
  alcohol?: Maybe<AlcoholHabit>;
  beard?: Maybe<Beard>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  birthDateChanged?: Maybe<Scalars['DateTime']['output']>;
  describeYourself?: Maybe<Scalars['String']['output']>;
  etnicity?: Maybe<Etnicity>;
  eyeColor?: Maybe<EyeColor>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  hairColor?: Maybe<HairColor>;
  hereTo?: Maybe<HereTo>;
  interests?: Maybe<Array<Interest>>;
  kids?: Maybe<KidsStatus>;
  languages?: Maybe<Array<Language>>;
  lastName?: Maybe<Scalars['String']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  living?: Maybe<Living>;
  location?: Maybe<ProfileLocation>;
  lookingFor?: Maybe<Array<Gender>>;
  mood?: Maybe<Scalars['String']['output']>;
  personality?: Maybe<Array<PersonalityTrait>>;
  relationshipStatus?: Maybe<RelationshipStatus>;
  religion?: Maybe<Religion>;
  sexuality?: Maybe<Sexuality>;
  smoking?: Maybe<SmokingHabit>;
  tattoo?: Maybe<Tattoo>;
  weight?: Maybe<Scalars['Int']['output']>;
  work?: Maybe<ProfileWork>;
  zodiac?: Maybe<Zodiac>;
};

export type ProfileInput = {
  alcohol?: InputMaybe<AlcoholHabit>;
  beard?: InputMaybe<Beard>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  describeYourself?: InputMaybe<Scalars['String']['input']>;
  etnicity?: InputMaybe<Etnicity>;
  eyeColor?: InputMaybe<EyeColor>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  hairColor?: InputMaybe<HairColor>;
  hereTo?: InputMaybe<HereTo>;
  interests?: InputMaybe<Array<Interest>>;
  kids?: InputMaybe<KidsStatus>;
  languages?: InputMaybe<Array<Language>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  length?: InputMaybe<Scalars['Int']['input']>;
  living?: InputMaybe<Living>;
  location?: InputMaybe<ProfileLocationInput>;
  lookingFor?: InputMaybe<Array<Gender>>;
  mood?: InputMaybe<Scalars['String']['input']>;
  personality?: InputMaybe<Array<PersonalityTrait>>;
  relationshipStatus?: InputMaybe<RelationshipStatus>;
  religion?: InputMaybe<Religion>;
  sexuality?: InputMaybe<Sexuality>;
  smoking?: InputMaybe<SmokingHabit>;
  tattoo?: InputMaybe<Tattoo>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  work?: InputMaybe<ProfileWorkInput>;
  zodiac?: InputMaybe<Zodiac>;
};

export type ProfileLocation = {
  __typename?: 'ProfileLocation';
  _id: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  geo?: Maybe<Geo>;
};

export type ProfileLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<GeoInput>;
};

export type ProfileWork = {
  __typename?: 'ProfileWork';
  _id: Scalars['String']['output'];
  company?: Maybe<Scalars['String']['output']>;
  education?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ProfileWorkInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  education?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']['output']>;
  analytics?: Maybe<AnalyticsResult>;
  attachmentUploads?: Maybe<PagedPhotos>;
  dashboard?: Maybe<DashboardData>;
  groupPhotos: Array<Group>;
  groups: Array<Group>;
  hello?: Maybe<Scalars['String']['output']>;
  matchVideosApproval?: Maybe<Array<User>>;
  photoVerifications?: Maybe<Array<PhotoVerification>>;
  privatePhotos?: Maybe<PagedPhotos>;
  privateVideos?: Maybe<PagedVideos>;
  profilePhotosApproval?: Maybe<Array<User>>;
  publicPostsPendingReview?: Maybe<Array<Post>>;
  reelsApproval: ReelsApprovalResult;
  subscriptions?: Maybe<SubscriptionsResult>;
  unverifiedPhotos?: Maybe<PagedPhotos>;
  unverifiedVideos?: Maybe<PagedVideos>;
  user?: Maybe<User>;
  userActivityMap: UserActivityMapResult;
  users: PagedUsers;
  verifiedPhotos?: Maybe<PagedPhotos>;
};


export type QueryAttachmentUploadsArgs = {
  paging: PagingArgs;
};


export type QueryPrivatePhotosArgs = {
  paging: PagingArgs;
};


export type QueryPrivateVideosArgs = {
  paging: PagingArgs;
};


export type QueryUnverifiedPhotosArgs = {
  paging: PagingArgs;
};


export type QueryUnverifiedVideosArgs = {
  paging: PagingArgs;
};


export type QueryUserArgs = {
  _id: Scalars['String']['input'];
};


export type QueryUserActivityMapArgs = {
  filters: Array<UserActivityFilter>;
  minutes: Scalars['Int']['input'];
};


export type QueryUsersArgs = {
  paging: PagingArgs;
  regex?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVerifiedPhotosArgs = {
  paging: PagingArgs;
};

export type Reel = {
  __typename?: 'Reel';
  _id: Scalars['String']['output'];
  byUserId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  thumbnailBlurhash?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  uploadComplete: Scalars['Boolean']['output'];
  uploadId: Scalars['String']['output'];
  url: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type ReelsApprovalResult = {
  __typename?: 'ReelsApprovalResult';
  latestReels: Array<Reel>;
  reels: Array<Reel>;
};

export enum RelationshipStatus {
  Complicated = 'COMPLICATED',
  InOpenRelationship = 'IN_OPEN_RELATIONSHIP',
  InRelationship = 'IN_RELATIONSHIP',
  Married = 'MARRIED',
  Other = 'OTHER',
  Single = 'SINGLE',
  Undisclosed = 'UNDISCLOSED'
}

export enum Religion {
  Agnostic = 'Agnostic',
  Atheist = 'Atheist',
  Buddhist = 'Buddhist',
  Catholic = 'Catholic',
  Christian = 'Christian',
  Hindu = 'Hindu',
  Jain = 'Jain',
  Jewish = 'Jewish',
  Muslim = 'Muslim',
  Other = 'Other',
  Parsi = 'Parsi',
  Sikh = 'Sikh',
  Spiritual = 'Spiritual',
  Undisclosed = 'UNDISCLOSED'
}

export type Report = {
  __typename?: 'Report';
  _id: Scalars['String']['output'];
  byUserId?: Maybe<Scalars['String']['output']>;
  contentId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  problem?: Maybe<ReportProblem>;
  resolved?: Maybe<Scalars['Boolean']['output']>;
  resolvedByEmail?: Maybe<Scalars['String']['output']>;
  resolvedDate?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<ReportType>;
};

export enum ReportProblem {
  Bullying = 'BULLYING',
  Harassment = 'HARASSMENT',
  HateSpeech = 'HATE_SPEECH',
  IntelectualProperty = 'INTELECTUAL_PROPERTY',
  Nudity = 'NUDITY',
  PrivacyViolation = 'PRIVACY_VIOLATION',
  Scam = 'SCAM',
  SelfInjury = 'SELF_INJURY',
  SexualServices = 'SEXUAL_SERVICES',
  Spam = 'SPAM',
  Terrorism = 'TERRORISM',
  Violence = 'VIOLENCE'
}

export enum ReportType {
  Comment = 'COMMENT',
  Group = 'GROUP',
  Live = 'LIVE',
  Message = 'MESSAGE',
  Post = 'POST',
  Reel = 'REEL',
  User = 'USER'
}

export enum Sexuality {
  AskMe = 'ASK_ME',
  Bisexual = 'BISEXUAL',
  Hetero = 'HETERO',
  Homo = 'HOMO',
  Other = 'OTHER',
  Undisclosed = 'UNDISCLOSED'
}

export enum SmokingHabit {
  HateSmoking = 'HATE_SMOKING',
  No = 'NO',
  Undisclosed = 'UNDISCLOSED',
  YesEveryDay = 'YES_EVERY_DAY',
  YesParties = 'YES_PARTIES',
  YesSometimes = 'YES_SOMETIMES'
}

export type SubscriptionData = {
  __typename?: 'SubscriptionData';
  age: Scalars['Int']['output'];
  country: Scalars['String']['output'];
  expireDate: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type SubscriptionsResult = {
  __typename?: 'SubscriptionsResult';
  data?: Maybe<Array<SubscriptionData>>;
  totalSubscriptions?: Maybe<Scalars['Int']['output']>;
  totalSubscriptionsLast30days?: Maybe<Scalars['Int']['output']>;
};

export enum Tattoo {
  Many = 'MANY',
  No = 'NO',
  One = 'ONE',
  Undisclosed = 'UNDISCLOSED'
}

export type TotalUsersCountryItem = {
  __typename?: 'TotalUsersCountryItem';
  country: Scalars['String']['output'];
  users: Scalars['Int']['output'];
};

export type TotalUsersItem = {
  __typename?: 'TotalUsersItem';
  date: Scalars['DateTime']['output'];
  users: Scalars['Int']['output'];
};

export type UploadField = {
  __typename?: 'UploadField';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UploadPostInfo = {
  __typename?: 'UploadPostInfo';
  fields?: Maybe<Array<Maybe<UploadField>>>;
  fileName: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['String']['output'];
  admin?: Maybe<Scalars['Boolean']['output']>;
  banned?: Maybe<Scalars['Boolean']['output']>;
  bannedUntilDate?: Maybe<Scalars['DateTime']['output']>;
  country: Scalars['String']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  credits?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  lastActive?: Maybe<Scalars['DateTime']['output']>;
  loginDate?: Maybe<Scalars['DateTime']['output']>;
  matchVideo?: Maybe<Video>;
  matchVideoApproved?: Maybe<Scalars['Boolean']['output']>;
  photos?: Maybe<Array<Photo>>;
  platform: Scalars['String']['output'];
  premium?: Maybe<Scalars['Boolean']['output']>;
  profilePhoto?: Maybe<Photo>;
  profilePhotoApproved?: Maybe<Scalars['Boolean']['output']>;
  translationTokens?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum UserActivityFilter {
  Men = 'Men',
  NewSignups = 'NewSignups',
  OldSignups = 'OldSignups',
  Premium = 'Premium',
  Women = 'Women'
}

export type UserActivityMapData = {
  __typename?: 'UserActivityMapData';
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  lastActive: Scalars['DateTime']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  photo?: Maybe<Photo>;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type UserActivityMapResult = {
  __typename?: 'UserActivityMapResult';
  data: Array<UserActivityMapData>;
};

export type Video = {
  __typename?: 'Video';
  _id: Scalars['String']['output'];
  approved: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  thumbnailBlurhash?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  uploadById?: Maybe<Scalars['String']['output']>;
  uploadComplete: Scalars['Boolean']['output'];
  uploadId: Scalars['String']['output'];
  url: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type VideoThumbnailUploadArgs = {
  contentLength: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  videoUploadId: Scalars['String']['input'];
};

export type VideoUploadArgs = {
  contentLength: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type VideoUploadResult = {
  __typename?: 'VideoUploadResult';
  uploadId: Scalars['String']['output'];
  uploadPostInfo: UploadPostInfo;
};

export enum Zodiac {
  Aquarius = 'Aquarius',
  Aries = 'Aries',
  Cancer = 'Cancer',
  Capricornus = 'Capricornus',
  Gemini = 'Gemini',
  Leo = 'Leo',
  Libra = 'Libra',
  Pisces = 'Pisces',
  Sagittarius = 'Sagittarius',
  Scorpius = 'Scorpius',
  Taurus = 'Taurus',
  Undisclosed = 'UNDISCLOSED',
  Virgo = 'Virgo'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AlcoholHabit: AlcoholHabit;
  AnalyticsResult: ResolverTypeWrapper<AnalyticsResult>;
  Attachment: ResolverTypeWrapper<Attachment>;
  Beard: Beard;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CursorQueryArgs: CursorQueryArgs;
  DashboardData: ResolverTypeWrapper<DashboardData>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DauItem: ResolverTypeWrapper<DauItem>;
  Etnicity: Etnicity;
  EyeColor: EyeColor;
  FileUploadArgs: FileUploadArgs;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Gender: Gender;
  Geo: ResolverTypeWrapper<Geo>;
  GeoInput: GeoInput;
  GeocodeResult: ResolverTypeWrapper<GeocodeResult>;
  Group: ResolverTypeWrapper<Group>;
  GroupPrivacy: GroupPrivacy;
  GroupStatus: GroupStatus;
  GroupVisibility: GroupVisibility;
  HairColor: HairColor;
  HereTo: HereTo;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Interest: Interest;
  KidsStatus: KidsStatus;
  LIVING: Living;
  Language: Language;
  LanguageString: ResolverTypeWrapper<LanguageString>;
  Location: ResolverTypeWrapper<Location>;
  LocationInput: LocationInput;
  LoginResult: ResolverTypeWrapper<LoginResult>;
  MeetType: MeetType;
  MeetWhen: MeetWhen;
  MemberVisibility: MemberVisibility;
  Mutation: ResolverTypeWrapper<{}>;
  OffsetQueryArgs: OffsetQueryArgs;
  PageQueryArgs: PageQueryArgs;
  PagedPhotos: ResolverTypeWrapper<PagedPhotos>;
  PagedUsers: ResolverTypeWrapper<PagedUsers>;
  PagedVideos: ResolverTypeWrapper<PagedVideos>;
  PagingArgs: PagingArgs;
  PagingResult: ResolverTypeWrapper<PagingResult>;
  PersonalityTrait: PersonalityTrait;
  Photo: ResolverTypeWrapper<Photo>;
  PhotoUploadArgs: PhotoUploadArgs;
  PhotoUploadResult: ResolverTypeWrapper<PhotoUploadResult>;
  PhotoVerification: ResolverTypeWrapper<PhotoVerification>;
  PlaceAutocomplete: ResolverTypeWrapper<PlaceAutocomplete>;
  Post: ResolverTypeWrapper<Post>;
  PostAudience: PostAudience;
  PostReviewStatus: PostReviewStatus;
  Profile: ResolverTypeWrapper<Profile>;
  ProfileInput: ProfileInput;
  ProfileLocation: ResolverTypeWrapper<ProfileLocation>;
  ProfileLocationInput: ProfileLocationInput;
  ProfileWork: ResolverTypeWrapper<ProfileWork>;
  ProfileWorkInput: ProfileWorkInput;
  Query: ResolverTypeWrapper<{}>;
  Reel: ResolverTypeWrapper<Reel>;
  ReelsApprovalResult: ResolverTypeWrapper<ReelsApprovalResult>;
  RelationshipStatus: RelationshipStatus;
  Religion: Religion;
  Report: ResolverTypeWrapper<Report>;
  ReportProblem: ReportProblem;
  ReportType: ReportType;
  Sexuality: Sexuality;
  SmokingHabit: SmokingHabit;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  SubscriptionData: ResolverTypeWrapper<SubscriptionData>;
  SubscriptionsResult: ResolverTypeWrapper<SubscriptionsResult>;
  TATTOO: Tattoo;
  TotalUsersCountryItem: ResolverTypeWrapper<TotalUsersCountryItem>;
  TotalUsersItem: ResolverTypeWrapper<TotalUsersItem>;
  UploadField: ResolverTypeWrapper<UploadField>;
  UploadPostInfo: ResolverTypeWrapper<UploadPostInfo>;
  User: ResolverTypeWrapper<User>;
  UserActivityFilter: UserActivityFilter;
  UserActivityMapData: ResolverTypeWrapper<UserActivityMapData>;
  UserActivityMapResult: ResolverTypeWrapper<UserActivityMapResult>;
  Video: ResolverTypeWrapper<Video>;
  VideoThumbnailUploadArgs: VideoThumbnailUploadArgs;
  VideoUploadArgs: VideoUploadArgs;
  VideoUploadResult: ResolverTypeWrapper<VideoUploadResult>;
  Zodiac: Zodiac;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AnalyticsResult: AnalyticsResult;
  Attachment: Attachment;
  Boolean: Scalars['Boolean']['output'];
  CursorQueryArgs: CursorQueryArgs;
  DashboardData: DashboardData;
  DateTime: Scalars['DateTime']['output'];
  DauItem: DauItem;
  FileUploadArgs: FileUploadArgs;
  Float: Scalars['Float']['output'];
  Geo: Geo;
  GeoInput: GeoInput;
  GeocodeResult: GeocodeResult;
  Group: Group;
  Int: Scalars['Int']['output'];
  LanguageString: LanguageString;
  Location: Location;
  LocationInput: LocationInput;
  LoginResult: LoginResult;
  Mutation: {};
  OffsetQueryArgs: OffsetQueryArgs;
  PageQueryArgs: PageQueryArgs;
  PagedPhotos: PagedPhotos;
  PagedUsers: PagedUsers;
  PagedVideos: PagedVideos;
  PagingArgs: PagingArgs;
  PagingResult: PagingResult;
  Photo: Photo;
  PhotoUploadArgs: PhotoUploadArgs;
  PhotoUploadResult: PhotoUploadResult;
  PhotoVerification: PhotoVerification;
  PlaceAutocomplete: PlaceAutocomplete;
  Post: Post;
  Profile: Profile;
  ProfileInput: ProfileInput;
  ProfileLocation: ProfileLocation;
  ProfileLocationInput: ProfileLocationInput;
  ProfileWork: ProfileWork;
  ProfileWorkInput: ProfileWorkInput;
  Query: {};
  Reel: Reel;
  ReelsApprovalResult: ReelsApprovalResult;
  Report: Report;
  String: Scalars['String']['output'];
  SubscriptionData: SubscriptionData;
  SubscriptionsResult: SubscriptionsResult;
  TotalUsersCountryItem: TotalUsersCountryItem;
  TotalUsersItem: TotalUsersItem;
  UploadField: UploadField;
  UploadPostInfo: UploadPostInfo;
  User: User;
  UserActivityMapData: UserActivityMapData;
  UserActivityMapResult: UserActivityMapResult;
  Video: Video;
  VideoThumbnailUploadArgs: VideoThumbnailUploadArgs;
  VideoUploadArgs: VideoUploadArgs;
  VideoUploadResult: VideoUploadResult;
};

export type AnalyticsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnalyticsResult'] = ResolversParentTypes['AnalyticsResult']> = {
  daus?: Resolver<Maybe<Array<ResolversTypes['DauItem']>>, ParentType, ContextType>;
  totalActiveUsersLast30days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalActiveUsersLast30daysCreatedMoreThan90daysAgo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalFemaleUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalMaleUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalNewUsersLast30days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalOtherGenderUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPayingUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPayingUsersLast30days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUsers?: Resolver<Maybe<Array<ResolversTypes['TotalUsersItem']>>, ParentType, ContextType>;
  totalUsersByCountry?: Resolver<Maybe<Array<ResolversTypes['TotalUsersCountryItem']>>, ParentType, ContextType>;
  totalUsersLoginApple?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUsersLoginEmail?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUsersLoginFacebook?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUsersLoginGoogle?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUsersPlatformAndroid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUsersPlatformIos?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUsersPlatformWeb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUsersWithPremium?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalVerifiedUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = {
  blurhash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  expired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  thumbnailUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardData'] = ResolversParentTypes['DashboardData']> = {
  dailyActiveUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  monthlyActiveUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalBannedUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGroups?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalInvites?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPrivatePhotos?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUnresolvedReports?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUnverifiedPhotos?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUnverifiedUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUsersWithApprovedPhoto?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DauItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['DauItem'] = ResolversParentTypes['DauItem']> = {
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  mau?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Geo'] = ResolversParentTypes['Geo']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coordinates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeocodeResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeocodeResult'] = ResolversParentTypes['GeocodeResult']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  about?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  genderLimited?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  maxAgeLimited?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  memberCommentsAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  memberPostsAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  memberVisibility?: Resolver<ResolversTypes['MemberVisibility'], ParentType, ContextType>;
  minAgeLimited?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['Photo']>, ParentType, ContextType>;
  privacy?: Resolver<ResolversTypes['GroupPrivacy'], ParentType, ContextType>;
  rules?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['GroupVisibility'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LanguageStringResolvers<ContextType = any, ParentType extends ResolversParentTypes['LanguageString'] = ResolversParentTypes['LanguageString']> = {
  lang?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['Geo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginResult'] = ResolversParentTypes['LoginResult']> = {
  authenticated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  approveGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationApproveGroupArgs, 'groupId' | 'value'>>;
  approveGroupPhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationApproveGroupPhotoArgs, 'groupId' | 'value'>>;
  approveMatchVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationApproveMatchVideoArgs, 'userId' | 'value'>>;
  approvePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationApprovePhotoArgs, 'uploadId' | 'value'>>;
  approvePhotoVerification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationApprovePhotoVerificationArgs, 'userId' | 'value'>>;
  approveProfilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationApproveProfilePhotoArgs, 'userId' | 'value'>>;
  approveReel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationApproveReelArgs, 'reelId' | 'value'>>;
  approveVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationApproveVideoArgs, 'uploadId' | 'value'>>;
  deleteUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'userId'>>;
  googleLogin?: Resolver<Maybe<ResolversTypes['LoginResult']>, ParentType, ContextType, RequireFields<MutationGoogleLoginArgs, 'captcha' | 'token'>>;
  reviewPost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationReviewPostArgs, 'postId' | 'reviewStatus'>>;
  setBan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSetBanArgs, '_id' | 'value'>>;
};

export type PagedPhotosResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedPhotos'] = ResolversParentTypes['PagedPhotos']> = {
  nodes?: Resolver<Array<ResolversTypes['Photo']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedUsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedUsers'] = ResolversParentTypes['PagedUsers']> = {
  nodes?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedVideosResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedVideos'] = ResolversParentTypes['PagedVideos']> = {
  nodes?: Resolver<Array<ResolversTypes['Video']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagingResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagingResult'] = ResolversParentTypes['PagingResult']> = {
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPrevPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pagingCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhotoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Photo'] = ResolversParentTypes['Photo']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blurhash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  thumbnailUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uploadComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhotoUploadResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhotoUploadResult'] = ResolversParentTypes['PhotoUploadResult']> = {
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadPostInfo?: Resolver<ResolversTypes['UploadPostInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhotoVerificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhotoVerification'] = ResolversParentTypes['PhotoVerification']> = {
  profilePhoto?: Resolver<ResolversTypes['Photo'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verifyPhoto?: Resolver<ResolversTypes['Photo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlaceAutocompleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlaceAutocomplete'] = ResolversParentTypes['PlaceAutocomplete']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['Geo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostResolvers<ContextType = any, ParentType extends ResolversParentTypes['Post'] = ResolversParentTypes['Post']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<ResolversTypes['Attachment']>>, ParentType, ContextType>;
  audience?: Resolver<ResolversTypes['PostAudience'], ParentType, ContextType>;
  byUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  reviewStatus?: Resolver<ResolversTypes['PostReviewStatus'], ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['Profile'] = ResolversParentTypes['Profile']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  alcohol?: Resolver<Maybe<ResolversTypes['AlcoholHabit']>, ParentType, ContextType>;
  beard?: Resolver<Maybe<ResolversTypes['Beard']>, ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  birthDateChanged?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  describeYourself?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  etnicity?: Resolver<Maybe<ResolversTypes['Etnicity']>, ParentType, ContextType>;
  eyeColor?: Resolver<Maybe<ResolversTypes['EyeColor']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  hairColor?: Resolver<Maybe<ResolversTypes['HairColor']>, ParentType, ContextType>;
  hereTo?: Resolver<Maybe<ResolversTypes['HereTo']>, ParentType, ContextType>;
  interests?: Resolver<Maybe<Array<ResolversTypes['Interest']>>, ParentType, ContextType>;
  kids?: Resolver<Maybe<ResolversTypes['KidsStatus']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<Array<ResolversTypes['Language']>>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  living?: Resolver<Maybe<ResolversTypes['LIVING']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['ProfileLocation']>, ParentType, ContextType>;
  lookingFor?: Resolver<Maybe<Array<ResolversTypes['Gender']>>, ParentType, ContextType>;
  mood?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personality?: Resolver<Maybe<Array<ResolversTypes['PersonalityTrait']>>, ParentType, ContextType>;
  relationshipStatus?: Resolver<Maybe<ResolversTypes['RelationshipStatus']>, ParentType, ContextType>;
  religion?: Resolver<Maybe<ResolversTypes['Religion']>, ParentType, ContextType>;
  sexuality?: Resolver<Maybe<ResolversTypes['Sexuality']>, ParentType, ContextType>;
  smoking?: Resolver<Maybe<ResolversTypes['SmokingHabit']>, ParentType, ContextType>;
  tattoo?: Resolver<Maybe<ResolversTypes['TATTOO']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  work?: Resolver<Maybe<ResolversTypes['ProfileWork']>, ParentType, ContextType>;
  zodiac?: Resolver<Maybe<ResolversTypes['Zodiac']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileLocation'] = ResolversParentTypes['ProfileLocation']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['Geo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileWorkResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileWork'] = ResolversParentTypes['ProfileWork']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  education?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  analytics?: Resolver<Maybe<ResolversTypes['AnalyticsResult']>, ParentType, ContextType>;
  attachmentUploads?: Resolver<Maybe<ResolversTypes['PagedPhotos']>, ParentType, ContextType, RequireFields<QueryAttachmentUploadsArgs, 'paging'>>;
  dashboard?: Resolver<Maybe<ResolversTypes['DashboardData']>, ParentType, ContextType>;
  groupPhotos?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>;
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>;
  hello?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  matchVideosApproval?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  photoVerifications?: Resolver<Maybe<Array<ResolversTypes['PhotoVerification']>>, ParentType, ContextType>;
  privatePhotos?: Resolver<Maybe<ResolversTypes['PagedPhotos']>, ParentType, ContextType, RequireFields<QueryPrivatePhotosArgs, 'paging'>>;
  privateVideos?: Resolver<Maybe<ResolversTypes['PagedVideos']>, ParentType, ContextType, RequireFields<QueryPrivateVideosArgs, 'paging'>>;
  profilePhotosApproval?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  publicPostsPendingReview?: Resolver<Maybe<Array<ResolversTypes['Post']>>, ParentType, ContextType>;
  reelsApproval?: Resolver<ResolversTypes['ReelsApprovalResult'], ParentType, ContextType>;
  subscriptions?: Resolver<Maybe<ResolversTypes['SubscriptionsResult']>, ParentType, ContextType>;
  unverifiedPhotos?: Resolver<Maybe<ResolversTypes['PagedPhotos']>, ParentType, ContextType, RequireFields<QueryUnverifiedPhotosArgs, 'paging'>>;
  unverifiedVideos?: Resolver<Maybe<ResolversTypes['PagedVideos']>, ParentType, ContextType, RequireFields<QueryUnverifiedVideosArgs, 'paging'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, '_id'>>;
  userActivityMap?: Resolver<ResolversTypes['UserActivityMapResult'], ParentType, ContextType, RequireFields<QueryUserActivityMapArgs, 'filters' | 'minutes'>>;
  users?: Resolver<ResolversTypes['PagedUsers'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'paging'>>;
  verifiedPhotos?: Resolver<Maybe<ResolversTypes['PagedPhotos']>, ParentType, ContextType, RequireFields<QueryVerifiedPhotosArgs, 'paging'>>;
};

export type ReelResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reel'] = ResolversParentTypes['Reel']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  byUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailBlurhash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uploadComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReelsApprovalResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReelsApprovalResult'] = ResolversParentTypes['ReelsApprovalResult']> = {
  latestReels?: Resolver<Array<ResolversTypes['Reel']>, ParentType, ContextType>;
  reels?: Resolver<Array<ResolversTypes['Reel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  byUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  problem?: Resolver<Maybe<ResolversTypes['ReportProblem']>, ParentType, ContextType>;
  resolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  resolvedByEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resolvedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ReportType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionData'] = ResolversParentTypes['SubscriptionData']> = {
  age?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expireDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionsResult'] = ResolversParentTypes['SubscriptionsResult']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['SubscriptionData']>>, ParentType, ContextType>;
  totalSubscriptions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalSubscriptionsLast30days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TotalUsersCountryItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['TotalUsersCountryItem'] = ResolversParentTypes['TotalUsersCountryItem']> = {
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TotalUsersItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['TotalUsersItem'] = ResolversParentTypes['TotalUsersItem']> = {
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UploadFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadField'] = ResolversParentTypes['UploadField']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UploadPostInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadPostInfo'] = ResolversParentTypes['UploadPostInfo']> = {
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['UploadField']>>>, ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  admin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  banned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  bannedUntilDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  credits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActive?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  loginDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  matchVideo?: Resolver<Maybe<ResolversTypes['Video']>, ParentType, ContextType>;
  matchVideoApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  photos?: Resolver<Maybe<Array<ResolversTypes['Photo']>>, ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  premium?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['Photo']>, ParentType, ContextType>;
  profilePhotoApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  translationTokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserActivityMapDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserActivityMapData'] = ResolversParentTypes['UserActivityMapData']> = {
  birthDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastActive?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lng?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['Photo']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserActivityMapResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserActivityMapResult'] = ResolversParentTypes['UserActivityMapResult']> = {
  data?: Resolver<Array<ResolversTypes['UserActivityMapData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Video'] = ResolversParentTypes['Video']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  thumbnailBlurhash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uploadById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uploadComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoUploadResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoUploadResult'] = ResolversParentTypes['VideoUploadResult']> = {
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadPostInfo?: Resolver<ResolversTypes['UploadPostInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AnalyticsResult?: AnalyticsResultResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  DashboardData?: DashboardDataResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DauItem?: DauItemResolvers<ContextType>;
  Geo?: GeoResolvers<ContextType>;
  GeocodeResult?: GeocodeResultResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  LanguageString?: LanguageStringResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LoginResult?: LoginResultResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  PagedPhotos?: PagedPhotosResolvers<ContextType>;
  PagedUsers?: PagedUsersResolvers<ContextType>;
  PagedVideos?: PagedVideosResolvers<ContextType>;
  PagingResult?: PagingResultResolvers<ContextType>;
  Photo?: PhotoResolvers<ContextType>;
  PhotoUploadResult?: PhotoUploadResultResolvers<ContextType>;
  PhotoVerification?: PhotoVerificationResolvers<ContextType>;
  PlaceAutocomplete?: PlaceAutocompleteResolvers<ContextType>;
  Post?: PostResolvers<ContextType>;
  Profile?: ProfileResolvers<ContextType>;
  ProfileLocation?: ProfileLocationResolvers<ContextType>;
  ProfileWork?: ProfileWorkResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Reel?: ReelResolvers<ContextType>;
  ReelsApprovalResult?: ReelsApprovalResultResolvers<ContextType>;
  Report?: ReportResolvers<ContextType>;
  SubscriptionData?: SubscriptionDataResolvers<ContextType>;
  SubscriptionsResult?: SubscriptionsResultResolvers<ContextType>;
  TotalUsersCountryItem?: TotalUsersCountryItemResolvers<ContextType>;
  TotalUsersItem?: TotalUsersItemResolvers<ContextType>;
  UploadField?: UploadFieldResolvers<ContextType>;
  UploadPostInfo?: UploadPostInfoResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserActivityMapData?: UserActivityMapDataResolvers<ContextType>;
  UserActivityMapResult?: UserActivityMapResultResolvers<ContextType>;
  Video?: VideoResolvers<ContextType>;
  VideoUploadResult?: VideoUploadResultResolvers<ContextType>;
};


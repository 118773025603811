import { useApolloClient } from "@apollo/client";
import { Box, useTheme } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useAuthStore } from "../../hooks/useAuth";
import { FlexRow, FlexRowHover } from "../Styled/Container";
import Text from "../Styled/Text";

const NavBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  height: 60px;
  width: 100%;
  background-color: ${(props) => props.theme.navBg};
  z-index: 1000;
`;
const NavBarWrapper = styled(FlexRow)`
  padding-right: 20px;
  padding-left: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const NavBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const client = useApolloClient();
  const setAuthenticated = useAuthStore.use.setAuthenticated();

  const handleLogout = () => {
    client.clearStore();
    setAuthenticated(false);
    Cookies.remove("session_token");
    localStorage.removeItem("auth_token");
  };

  return (
    <NavBarContainer>
      <NavBarWrapper>
        <FlexRow>
          <img width={26} src="logo-small.png" alt="logo" />
          <img width={100} src="logo-text-white.png" alt="logo" />
          <Box sx={{ width: 20, height: 20 }} />
          <Text size={16}>Backoffice</Text>
        </FlexRow>
        <FlexRow>
          <FlexRowHover onClick={() => handleLogout()}>
            <Text>Log out</Text>
          </FlexRowHover>
        </FlexRow>
      </NavBarWrapper>
    </NavBarContainer>
  );
};
export default NavBar;

import type { Marker } from "@googlemaps/markerclusterer";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { useCallback } from "react";
import Avatar from "../../components/Avatar/Avatar";
import { Poi } from "./ClusteredPoiMarkers";

export type PoiMarkerProps = {
  poi: Poi;
  onClick: (poi: Poi) => void;
  setMarkerRef: (marker: Marker | null, key: string) => void;
};

/**
 * Wrapper Component for an AdvancedMarker for a single poi.
 */
export const PoiMarker = (props: PoiMarkerProps) => {
  const { poi, onClick, setMarkerRef } = props;

  const handleClick = useCallback(() => onClick(poi), [onClick, poi]);
  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) =>
      setMarkerRef(marker, poi.key),
    [setMarkerRef, poi.key]
  );

  return (
    <AdvancedMarker position={poi.location} ref={ref} onClick={handleClick}>
      {/* <UserIcon width={20} color={theme.colors.red} /> */}
      {/* <Pin background={"#FBBC04"} glyphColor={"#000"} borderColor={"#000"} /> */}
      <Avatar
        name={poi.userActivityMapData.username ?? ""}
        url={
          poi.userActivityMapData.photo?.uploadComplete
            ? poi.userActivityMapData.photo?.thumbnailUrl
            : ""
        }
      />
    </AdvancedMarker>
  );
};

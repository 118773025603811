import { useMutation, useQuery } from "@apollo/client";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { IconButton, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import Loader from "../../components/Loader/Loader";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { User } from "../../graphql/graphql";
import { APPROVE_PROFILE_PHOTO } from "../../graphql/mutations-gql";
import { PROFILE_PHOTOS_APPROVAL } from "../../graphql/queries-gql";
import {
  PhotoActionContainer,
  PhotoContainer,
  PhotoIconContainer,
  PhotoThumbnail,
} from "../unverified-photos/styled";

const ProfilePhotos = () => {
  const { data, loading, error } = useQuery(PROFILE_PHOTOS_APPROVAL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setUsers(data.profilePhotosApproval || []);
    },
  });
  const theme = useTheme();
  const [approveProfilePhoto] = useMutation(APPROVE_PROFILE_PHOTO);
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);

  const handleApprove = (value: boolean, userId: string) => {
    approveProfilePhoto({
      variables: {
        userId,
        value,
      },
      onCompleted: (data) => {
        setUsers((prev) => {
          return prev.filter((user) => user._id !== userId);
        });
        console.log("approveProfilePhoto onCompleted", data);
      },
      onError: (error) => {
        console.log("approveProfilePhoto onError", error);
      },
    });
  };

  const showImage = (src: string) => {
    //window.location.href = src;
    window.open(src, "_blank");
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Profile photos
        </Text>
      </FlexRow>

      {loading && (
        <FlexRow>
          <Loader />
        </FlexRow>
      )}
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}

      <FlexRow colGap={10}>
        {users.map((user) => {
          return (
            <PhotoContainer size={300} key={user.profilePhoto?.uploadId}>
              <PhotoIconContainer className="action-container">
                <FlexRow>
                  <IconButton
                    onClick={() => navigate(`/user/${user._id}`)}
                    sx={{
                      width: "48px",
                      height: "48px",
                      padding: "5px",
                      backgroundColor: theme.colors.gray,
                    }}
                  >
                    <UserIcon width={20} />
                  </IconButton>
                  <Text size={14} weight={600}>
                    {user.username} {user.platform} {user.country}
                  </Text>
                </FlexRow>
              </PhotoIconContainer>
              <PhotoActionContainer className="action-container">
                <IconButton
                  onClick={() => handleApprove(true, user._id)}
                  sx={{
                    width: "48px",
                    height: "48px",
                    padding: "5px",
                    backgroundColor: theme.colors.green,
                  }}
                >
                  <HandThumbUpIcon width={20} />
                </IconButton>
                <IconButton
                  onClick={() => handleApprove(false, user._id)}
                  sx={{
                    width: "48px",
                    height: "48px",
                    padding: "5px",
                    backgroundColor: theme.colors.red,
                  }}
                >
                  <HandThumbDownIcon width={20} />
                </IconButton>
              </PhotoActionContainer>
              <PhotoThumbnail
                size={300}
                src={user.profilePhoto?.url ?? ""}
                onClick={() => showImage(user.profilePhoto?.url ?? "")}
              />
            </PhotoContainer>
          );
        })}
      </FlexRow>
    </FlexCol>
  );
};
export default ProfilePhotos;

import { useQuery } from "@apollo/client";
import { useState } from "react";
import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { SubscriptionData, SubscriptionsResult } from "../../graphql/graphql";
import { SUBSCRIPTIONS } from "../../graphql/queries-gql";

type AgeCount = {
  age: number;
  count: number;
};

const Subscriptions = () => {
  const [ageCount, setAgeCount] = useState<AgeCount[]>([]);
  const { data, loading, error } = useQuery<{
    subscriptions: SubscriptionsResult;
  }>(SUBSCRIPTIONS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("onCompleted", data);
      try {
        const ageData = createAgeData(data.subscriptions.data);
        console.log("ageData", ageData);
        setAgeCount(ageData);
      } catch (e) {
        console.log("error", e);
      }
    },
  });

  // Create a table to display for ages 18-50 with amount of users in each age group.
  const createAgeData = (subscriptionsData?: SubscriptionData[] | null) => {
    const ageData: AgeCount[] = [];
    for (let i = 18; i <= 50; i++) {
      ageData.push({ age: i, count: 0 });
    }
    subscriptionsData?.forEach((user) => {
      if (user.age !== undefined && user.age !== null) {
        let added = false;
        for (let i = 0; i < ageData.length; i++) {
          if (ageData[i].age === user.age) {
            ageData[i].count++;
            added = true;
            break;
          }
        }
        if (!added) {
          ageData.push({ age: user.age, count: 1 });
        }
      }
    });

    console.log("created age data", ageData);
    return ageData;
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text weight={600}>Subscriptions</Text>
      </FlexRow>
      <FlexRow>
        <Text>Age distribution</Text>
      </FlexRow>
      <FlexRow>
        <BarChart width={300} height={300} data={ageCount}>
          <Bar type="monotone" dataKey="count" fill="#8884d8" />
          <XAxis dataKey="age" />
          <YAxis />
        </BarChart>
      </FlexRow>
    </FlexCol>
  );
};
export default Subscriptions;

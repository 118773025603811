import { Badge, Avatar as MuiAvatar, styled } from "@mui/material";
//import styled from "styled-components";
import styledc from "styled-components";

// https://mui.com/material-ui/react-avatar/

const StyledBadgeRipple = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const MessageBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 2px",
    backgroundColor: "#D73943",
    color: "#FFF",
  },
}));

const OnlineBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      content: '""',
    },
  },
}));

const AvatarContainer = styledc.div`
  position: relative;
  overflow: visible;
`;

const StyledMuiAvatar = styled(MuiAvatar)``;

const Avatar: React.FC<{
  url: string;
  name?: string;
  sx?: any;
  newMessages?: number;
  online?: boolean;
  onClick?: () => void;
  variant?: "circular" | "rounded" | "square";
  className?: string;
  moodEmoji?: string | null;
  moodEmojiSize?: number;
}> = (props) => {
  const ripple = false;

  if (ripple) {
    return (
      <>
        <StyledBadgeRipple
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <MuiAvatar alt={props.name} src={props.url} sx={props.sx} />
        </StyledBadgeRipple>
      </>
    );
  } else {
    return (
      <AvatarContainer>
        <OnlineBadge
          invisible={!props.online}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <MessageBadge
            overlap="circular"
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            badgeContent={props.newMessages ?? 0}
          >
            <StyledMuiAvatar
              className={props.className}
              variant={props.variant ? props.variant : "circular"}
              alt={props.name}
              src={props.url}
              sx={props.sx}
              onClick={props.onClick}
            />
          </MessageBadge>
        </OnlineBadge>
      </AvatarContainer>
    );
  }
};
export default Avatar;

import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import Loader from "../../components/Loader/Loader";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import { PagingResult, Photo } from "../../graphql/graphql";
import { PRIVATE_PHOTOS, UNVERIFIED_PHOTOS } from "../../graphql/queries-gql";
import Text from "../../components/Styled/Text";
import styled from "styled-components";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { IconButton, useTheme } from "@mui/material";
import { APPROVE_PHOTO } from "../../graphql/mutations-gql";
import { useNavigate } from "react-router-dom";

const PAGE_SIZE = 20;

const PhotoThumbnail = styled.img<{ src: string }>`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
`;
const PhotoContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;

  &:hover {
    cursor: pointer;

    .action-container {
      display: flex;
    }
  }
`;

const ActionContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 5px;
  display: none;
  flex-direction: row;
  column-gap: 5px;
  z-index: 100;
  justify-content: center;
`;

const IconContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 5px;
  top: 5px;
  display: none;
  flex-direction: row;
  column-gap: 5px;
  z-index: 100;
  justify-content: flex-start;
`;

const PrivatePhotos = () => {
  const [fetchPhotos, { loading, error }] = useLazyQuery(PRIVATE_PHOTOS);
  const [page, setPage] = useState<PagingResult | undefined>(undefined);
  const [photos, setPhotos] = useState<Photo[]>([]);
  const theme = useTheme();
  const [approvePhoto] = useMutation(APPROVE_PHOTO);
  const navigate = useNavigate();

  useEffect(() => {
    if (!error && !page) {
      fetchPhotos({
        variables: {
          paging: {
            type: "page",
            pageArgs: {
              page: 1,
              limit: PAGE_SIZE,
            },
          },
        },
        onCompleted: (data) => {
          console.log("fetchPhotos onCompleted", data);
          setPage(data.privatePhotos.paging);
          setPhotos(data.privatePhotos.nodes);
        },
        onError: (error) => {
          console.log("fetchPhotos onError", error);
        },
      });
    }
  }, [error, fetchPhotos, page]);

  const showImage = (src: string) => {
    //window.location.href = src;
    window.open(src, "_blank");
  };

  const showMore = () => {
    fetchPhotos({
      variables: {
        paging: {
          type: "page",
          pageArgs: {
            page: page?.nextPage,
            limit: PAGE_SIZE,
          },
        },
      },
      onCompleted: (data) => {
        console.log("fetchPhotos onCompleted", data);
        setPage(data.privatePhotos.paging);
        setPhotos([...photos, ...data.privatePhotos.nodes]);
      },
      onError: (error) => {
        console.log("fetchPhotos onError", error);
      },
    });
  };

  const handleApprove = (value: boolean, uploadId: string) => {
    approvePhoto({
      variables: {
        uploadId,
        value,
      },
      onCompleted: (data) => {
        // Completion means we should remove the photo.
        const newPhotos = photos.filter((photo) => photo.uploadId !== uploadId);
        setPhotos(newPhotos);
      },
      onError: (error) => {
        console.log("approvePhoto onError", error);
      },
    });
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Private photos
        </Text>
      </FlexRow>

      {photos && photos.length > 0 && (
        <FlexRow colGap={10}>
          {photos.map((photo) => {
            return (
              <PhotoContainer key={photo.uploadId}>
                <IconContainer className="action-container">
                  <IconButton
                    onClick={() => navigate(`/user/${photo.uploadById}`)}
                    sx={{
                      width: "24px",
                      height: "24px",
                      padding: "5px",
                      backgroundColor: theme.colors.gray,
                    }}
                  >
                    <UserIcon width={20} />
                  </IconButton>
                </IconContainer>
                <ActionContainer className="action-container">
                  <IconButton
                    onClick={() => handleApprove(true, photo.uploadId)}
                    sx={{
                      width: "24px",
                      height: "24px",
                      padding: "5px",
                      backgroundColor: theme.colors.green,
                    }}
                  >
                    <HandThumbUpIcon width={20} />
                  </IconButton>
                  <IconButton
                    onClick={() => handleApprove(false, photo.uploadId)}
                    sx={{
                      width: "24px",
                      height: "24px",
                      padding: "5px",
                      backgroundColor: theme.colors.red,
                    }}
                  >
                    <HandThumbDownIcon width={20} />
                  </IconButton>
                </ActionContainer>
                <PhotoThumbnail
                  src={photo.thumbnailUrl}
                  onClick={() => showImage(photo.url)}
                />
              </PhotoContainer>
            );
          })}
        </FlexRow>
      )}

      {page?.hasNextPage && (
        <FlexRow justifyContent="center">
          <Text onClick={() => showMore()}>Shore more photos</Text>
        </FlexRow>
      )}

      {loading && (
        <FlexRow>
          <Loader />
        </FlexRow>
      )}
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}
    </FlexCol>
  );
};
export default PrivatePhotos;

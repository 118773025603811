import { useMutation, useQuery } from "@apollo/client";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { PUBLIC_POSTS_PENDING_REVIEW } from "../../graphql/queries-gql";
import Loader from "../../components/Loader/Loader";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import { Post, PostReviewStatus } from "../../graphql/graphql";
import { StyledLink } from "../../components/Styled/StyledLink";
import { PhotoThumbnail } from "../unverified-photos/styled";
import { IconButton, useTheme } from "@mui/material";
import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/solid";
import { REVIEW_POST } from "../../graphql/mutations-gql";

const PostReview = () => {
  const { data, loading, error } = useQuery(PUBLIC_POSTS_PENDING_REVIEW);
  const theme = useTheme();
  const [reviewPost] = useMutation(REVIEW_POST);

  const posts: Post[] = data?.publicPostsPendingReview || [];

  const showAttachment = (src: string) => {
    //window.location.href = src;
    window.open(src, "_blank");
  };

  const handleApprove = (reviewStatus: PostReviewStatus, postId: string) => {
    reviewPost({
      variables: {
        postId,
        reviewStatus,
      },
      onCompleted: (data) => {
        console.log("reviewPost onCompleted", data);
      },
      onError: (error) => {
        console.log("reviewPost onError", error);
      },
      refetchQueries: [PUBLIC_POSTS_PENDING_REVIEW],
    });
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Public post review
        </Text>
      </FlexRow>
      {loading && (
        <FlexRow>
          <Loader />
        </FlexRow>
      )}
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}
      {posts.map((post) => {
        return (
          <FlexCol key={post._id}>
            <FlexRow>
              <Text size={18} weight={600}>
                Post id: {post._id}
              </Text>
            </FlexRow>
            <FlexRow>
              <Text size={18} weight={600}>
                By user id:{" "}
                <StyledLink to={`/user/${post.byUserId}`}>
                  {post.byUserId}
                </StyledLink>
              </Text>
            </FlexRow>
            <FlexRow>
              <Text size={16} weight={600}>
                {post.text}
              </Text>
            </FlexRow>

            {post.attachments?.map((attachment) => {
              return (
                <FlexRow key={attachment.uploadId}>
                  <PhotoThumbnail
                    src={attachment.thumbnailUrl ?? ""}
                    onClick={() => showAttachment(attachment.url ?? "")}
                  />
                </FlexRow>
              );
            })}

            <FlexRow colGap={20}>
              <IconButton
                onClick={() =>
                  handleApprove(PostReviewStatus.Approved, post._id)
                }
                sx={{
                  width: "32px",
                  height: "32px",
                  padding: "5px",
                  backgroundColor: theme.colors.green,
                }}
              >
                <HandThumbUpIcon width={20} />
              </IconButton>
              <IconButton
                onClick={() =>
                  handleApprove(PostReviewStatus.Rejected, post._id)
                }
                sx={{
                  width: "32px",
                  height: "32px",
                  padding: "5px",
                  backgroundColor: theme.colors.red,
                }}
              >
                <HandThumbDownIcon width={20} />
              </IconButton>
            </FlexRow>
          </FlexCol>
        );
      })}
    </FlexCol>
  );
};
export default PostReview;

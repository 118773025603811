import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import Loader from "../../components/Loader/Loader";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import { USER } from "../../graphql/queries-gql";
import Text from "../../components/Styled/Text";
import { Photo, User } from "../../graphql/graphql";
import ObjectCard from "../../components/ObjectCard/ObjectCard";
import styled from "styled-components";
import {
  ExclamationTriangleIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";
import { useTheme } from "@mui/material";
import { StdButton } from "../../components/Button/Button";
import { DELETE_USER, SET_BAN } from "../../graphql/mutations-gql";
import { addDays } from "date-fns";

const PhotoImage = styled.img<{ src: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PhotoThumbnailContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
`;

const PhotoThumbnail = styled.img<{ src: string; notApproved: boolean }>`
  width: 120px;
  height: 120px;
  object-fit: cover;
  ${(props) => (props.notApproved ? "opacity: 0.3;" : "")}
`;

const NotApprovedContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2px;
`;

const IsPrivateContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  flex-direction: row;
  justify-content: flex-end;
  padding: 2px;
`;

const UserPage = () => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(USER, {
    fetchPolicy: "network-only",
    variables: {
      _id: id,
    },
  });
  const theme = useTheme();
  const [setBan] = useMutation(SET_BAN);
  const [deleteUser] = useMutation(DELETE_USER);

  if (loading) {
    return (
      <FlexCol>
        <FlexRow>
          <Loader />
        </FlexRow>
      </FlexCol>
    );
  }
  if (error) {
    return (
      <FlexCol>
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      </FlexCol>
    );
  }
  const user: User = data.user;
  const profilePhoto: Photo = data.user.profilePhoto;
  const photos: Photo[] = data.user.photos;

  const handleBan = (value: boolean, days?: number) => {
    let until: Date | null = null;
    if (days) {
      until = addDays(new Date(), days);
    }
    setBan({
      variables: {
        _id: user._id,
        value,
        until,
      },
      onCompleted: (data) => {
        console.log("setBan onCompleted", data);
      },
      onError: (error) => {
        console.log("setBan onError", error);
      },
      refetchQueries: [USER],
    });
  };

  const handleDelete = () => {
    deleteUser({
      variables: {
        userId: user._id,
      },
      onCompleted: (data) => {
        console.log("deleteUser onCompleted", data);
      },
      onError: (error) => {
        console.log("deleteUser onError", error);
      },
      refetchQueries: [USER],
    });
  };

  return (
    <FlexCol>
      <FlexRow alignItems="flex-end">
        {profilePhoto && profilePhoto.uploadComplete && (
          <Card width={200}>
            <PhotoImage src={profilePhoto.url} />
          </Card>
        )}

        {user.banned ? (
          <StdButton onClick={() => handleBan(false)}>Remove ban</StdButton>
        ) : (
          <>
            <StdButton onClick={() => handleBan(true, 10)}>
              Ban 10 days
            </StdButton>
            <StdButton onClick={() => handleBan(true, 365)}>
              Ban 1 year
            </StdButton>
          </>
        )}
        <StdButton onClick={() => handleDelete()}>Delete user</StdButton>
      </FlexRow>
      <ObjectCard object={user} title="User data" />

      {photos && photos.length > 0 && (
        <Card>
          <FlexCol>
            <Text size={18} weight={600}>
              Photo uploads
            </Text>
            <FlexRow colGap={10}>
              {photos.map((photo) => {
                return (
                  <PhotoThumbnailContainer key={photo.uploadId}>
                    <PhotoThumbnail
                      src={photo.thumbnailUrl}
                      notApproved={
                        photo.verified === true && photo.approved === false
                      }
                    />
                    {photo.verified === true && photo.approved === false && (
                      <NotApprovedContainer>
                        <ExclamationTriangleIcon
                          color={theme.colors.error}
                          width={20}
                        />
                      </NotApprovedContainer>
                    )}
                    {photo.isPrivate && (
                      <IsPrivateContainer>
                        <EyeSlashIcon color={theme.colors.blue} width={20} />
                      </IsPrivateContainer>
                    )}
                  </PhotoThumbnailContainer>
                );
              })}
            </FlexRow>
          </FlexCol>
        </Card>
      )}
    </FlexCol>
  );
};
export default UserPage;

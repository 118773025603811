import { ApolloError, ServerError } from "@apollo/client";
//import { Alert } from "react-bootstrap";
import { Alert } from "@mui/material";
import styled from "styled-components";
import { useAuthStore } from "../../hooks/useAuth";
import Text from "../Styled/Text";

/**
Errors look like this, for failed login

{
  "name": "ApolloError",
  "graphQLErrors": [],
  "clientErrors": [],
  "networkError": {
    "name": "ServerError",
    "response": {},
    "statusCode": 401,
    "result": {
      "errors": [
        {
          "message": "Mismatching user name and password",
          "locations": [{ "line": 2, "column": 3 }],
          "path": ["login"],
          "extensions": {
            "code": "AUTHENTICATION_FAILED",
            "stacktrace": [
              "GraphQLError: Mismatching user name and password",
              " at login (/Users/emil/RLBR/backend/dist/api/graphql/auth/mutations/login.js:20:15)",
              " at process.processTicksAndRejections (node:internal/process/task_queues:95:5)",
              " at async Object.login (/Users/emil/RLBR/backend/dist/api/graphql/auth/auth.resolver.js:18:20)"
            ]
          }
        }
      ],
      "data": { "login": null }
    }
  },
  "message": "Response not successful: Received status code 401"
}

 */

const StyledAlert = styled(Alert)`
  //width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

export const ErrorAlert: React.FC<{
  error: Error | ApolloError | string | undefined;
  onClose?: () => void;
}> = (props) => {
  const setAuthenticated = useAuthStore.use.setAuthenticated();
  const { error } = props;

  if (!error) {
    return (
      <StyledAlert severity="error" {...props}>
        <Text>{"Undefined error occurred."}</Text>
      </StyledAlert>
    );
  } else if (typeof error === "string") {
    return (
      <StyledAlert severity="error" {...props}>
        <Text>{error}</Text>
      </StyledAlert>
    );
  } else if (error instanceof ApolloError) {
    console.log("error", JSON.stringify(error, null, 2));

    const serverError = error?.networkError as ServerError;
    if (serverError && serverError.result && serverError.result.errors) {
      console.log("serverError", JSON.stringify(serverError, null, 2));
      const errors = serverError.result.errors;
      let i = 0;
      return errors.map((err: any) => {
        i++;
        return (
          <StyledAlert key={"error_" + i} severity="error" {...props}>
            <Text>{err.message}</Text>
          </StyledAlert>
        );
      });
    }

    const gqlErrors = error?.graphQLErrors;
    if (gqlErrors && gqlErrors.length > 0) {
      let i = 0;
      return gqlErrors.map((err: any) => {
        i++;

        if (err.message === "Missing authorization token in request.") {
          //return <Navigate to="/login" replace />;
          setAuthenticated(false);
        }

        return (
          <StyledAlert key={"error_" + i} severity="error" {...props}>
            <Text>{err.message}</Text>
          </StyledAlert>
        );
      });
    }

    console.log("errorname", error.name);
    console.log(error.message);

    if (error?.name === "ApolloError" && error.message) {
      return (
        <StyledAlert key={"apollo_error"} severity="error" {...props}>
          <Text>{error.message}</Text>
        </StyledAlert>
      );
    }
    if (error?.name === "AxiosError" && error.message) {
      return (
        <StyledAlert key={"axios_error"} severity="error" {...props}>
          <Text>{error.message}</Text>
        </StyledAlert>
      );
    }
  } else if (error instanceof Error) {
    return (
      <StyledAlert severity="error" {...props}>
        <Text>{error.message}</Text>
      </StyledAlert>
    );
  }

  return <></>;
};

import { useTheme } from "@mui/material";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import { useQuery } from "@apollo/client";
import { ANALYTICS } from "../../graphql/queries-gql";
import Loader from "../../components/Loader/Loader";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import Text from "../../components/Styled/Text";
import Card from "../../components/Card/Card";
import { TotalUsersCountryItem } from "../../graphql/graphql";
import { LineChart, Line, XAxis, YAxis, BarChart, Bar } from "recharts";

const Analytics = () => {
  const theme = useTheme();
  const { data, loading, error } = useQuery(ANALYTICS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("onCompleted", data);
    },
    onError: (error) => {
      console.log("onError", error);
    },
  });

  if (loading) {
    return (
      <FlexCol>
        <FlexRow justifyContent="center">
          <Loader />
        </FlexRow>
      </FlexCol>
    );
  }

  if (error) {
    return (
      <FlexCol>
        <FlexRow justifyContent="center">
          <ErrorAlert error={error} />
        </FlexRow>
      </FlexCol>
    );
  }

  if (!data) {
  }

  return (
    <>
      <FlexCol>
        <FlexRow>
          <Text weight={600}>Analytics</Text>
        </FlexRow>

        <FlexRow>
          <Card width={240}>
            <FlexCol padding={0}>
              <Text weight={600}>Total verified users</Text>
              <Text weight={600}>{data.analytics.totalVerifiedUsers}</Text>
            </FlexCol>
          </Card>
        </FlexRow>

        <FlexRow>
          <FlexCol padding={0}>
            <Text weight={600}>Daily active users 90 days</Text>
            <BarChart width={300} height={300} data={data.analytics.daus}>
              <Bar type="monotone" dataKey="users" fill="#8884d8" />
              {/* <XAxis dataKey="date" /> */}
              <YAxis />
            </BarChart>
          </FlexCol>
        </FlexRow>

        <FlexRow>
          <FlexCol padding={0}>
            <Text weight={600}>Monthly active users 90 days</Text>
            <BarChart width={300} height={300} data={data.analytics.daus}>
              <Bar type="monotone" dataKey="mau" fill="#8884d8" />
              {/* <XAxis dataKey="date" /> */}
              <YAxis />
            </BarChart>
          </FlexCol>
        </FlexRow>

        <FlexRow>
          <FlexCol padding={0}>
            <Text weight={600}>Total users 12 months</Text>
            <BarChart width={300} height={300} data={data.analytics.totalUsers}>
              <Bar type="monotone" dataKey="users" fill="#8884d8" />
              {/* <XAxis dataKey="date" /> */}
              <YAxis />
            </BarChart>
          </FlexCol>
        </FlexRow>

        <FlexRow>
          <Card width={240}>
            <FlexCol padding={0}>
              <Text weight={600}>Gender distribution</Text>
              <Text weight={400}>
                Female: {data.analytics.totalFemaleUsers} (
                {Math.round(
                  (data.analytics.totalFemaleUsers /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
              <Text weight={400}>
                Male: {data.analytics.totalMaleUsers} (
                {Math.round(
                  (data.analytics.totalMaleUsers /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
              <Text weight={400}>
                Other: {data.analytics.totalOtherGenderUsers} (
                {Math.round(
                  (data.analytics.totalOtherGenderUsers /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
            </FlexCol>
          </Card>
        </FlexRow>

        <FlexRow>
          <Card width={240}>
            <FlexCol padding={0}>
              <Text weight={600}>Store data</Text>
              <Text weight={400}>
                Premium: {data.analytics.totalUsersWithPremium} (
                {Math.round(
                  (data.analytics.totalUsersWithPremium /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
              <Text weight={400}>
                Paying: {data.analytics.totalPayingUsers} (
                {Math.round(
                  (data.analytics.totalPayingUsers /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
            </FlexCol>
          </Card>
        </FlexRow>

        <FlexRow>
          <Card width={240}>
            <FlexCol padding={0}>
              <Text weight={600}>Activity</Text>
              <Text weight={400}>
                Last 30 days: {data.analytics.totalActiveUsersLast30days} (
                {Math.round(
                  (data.analytics.totalActiveUsersLast30days /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
              <Text weight={400}>
                Paying last 30 days: {data.analytics.totalPayingUsersLast30days}{" "}
                (
                {Math.round(
                  (data.analytics.totalPayingUsersLast30days /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
              <Text weight={400}>
                Active last 30 (created 90 days ago):{" "}
                {
                  data.analytics
                    .totalActiveUsersLast30daysCreatedMoreThan90daysAgo
                }{" "}
                (
                {Math.round(
                  (data.analytics
                    .totalActiveUsersLast30daysCreatedMoreThan90daysAgo /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
            </FlexCol>
          </Card>
        </FlexRow>

        <FlexRow>
          <Card width={240}>
            <FlexCol padding={0}>
              <Text weight={600}>New users last 30 days</Text>
              <Text weight={400}>
                {data.analytics.totalNewUsersLast30days} (
                {Math.round(
                  (data.analytics.totalNewUsersLast30days /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
            </FlexCol>
          </Card>
        </FlexRow>
        <FlexRow>
          <Card width={240}>
            <FlexCol padding={0}>
              <Text weight={600}>Platform distribution</Text>
              <Text weight={400}>
                IOS: {data.analytics.totalUsersPlatformIos} (
                {Math.round(
                  (data.analytics.totalUsersPlatformIos /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
              <Text weight={400}>
                Android: {data.analytics.totalUsersPlatformAndroid} (
                {Math.round(
                  (data.analytics.totalUsersPlatformAndroid /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
              <Text weight={400}>
                Web: {data.analytics.totalUsersPlatformWeb} (
                {Math.round(
                  (data.analytics.totalUsersPlatformWeb /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
            </FlexCol>
          </Card>
        </FlexRow>

        <FlexRow>
          <Card width={240}>
            <FlexCol padding={0}>
              <Text weight={600}>Auth distribution</Text>
              <Text weight={400}>
                Apple: {data.analytics.totalUsersLoginApple} (
                {Math.round(
                  (data.analytics.totalUsersLoginApple /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
              <Text weight={400}>
                Google: {data.analytics.totalUsersLoginGoogle} (
                {Math.round(
                  (data.analytics.totalUsersLoginGoogle /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
              <Text weight={400}>
                Facebook: {data.analytics.totalUsersLoginFacebook} (
                {Math.round(
                  (data.analytics.totalUsersLoginFacebook /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
              <Text weight={400}>
                Email: {data.analytics.totalUsersLoginEmail} (
                {Math.round(
                  (data.analytics.totalUsersLoginEmail /
                    data.analytics.totalVerifiedUsers) *
                    100
                )}{" "}
                %)
              </Text>
            </FlexCol>
          </Card>
        </FlexRow>

        <FlexRow>
          <Card width={240}>
            <FlexCol padding={0}>
              <Text weight={600}>Country distribution</Text>

              {data.analytics.totalUsersByCountry.map(
                (item: TotalUsersCountryItem) => {
                  return (
                    <Text
                      key={item.country}
                      weight={400}
                      color={theme.colors.gray}
                    >
                      {item.country}: {item.users} (
                      {Math.round(
                        (item.users / data.analytics.totalVerifiedUsers) * 100
                      )}{" "}
                      %)
                    </Text>
                  );
                }
              )}
            </FlexCol>
          </Card>
        </FlexRow>
      </FlexCol>
    </>
  );
};
export default Analytics;

import { Box } from "@mui/material";
import Card from "../Card/Card";
import { FlexCol, FlexRow } from "../Styled/Container";
import Text from "../Styled/Text";

const ObjectCard: React.FC<{ object: any; title: string }> = ({
  object,
  title,
}) => {
  const keys = Object.keys(object);

  return (
    <Card>
      <FlexCol>
        <Text size={18} weight={600}>
          {title}
        </Text>
        {keys.map((key) => {
          return (
            <FlexRow key={key} wrap="nowrap">
              <Box sx={{ width: 200, minWidth: 200 }}>
                <Text>{key}</Text>
              </Box>
              <Text>{JSON.stringify(object[key], null, 2)}</Text>
            </FlexRow>
          );
        })}
      </FlexCol>
    </Card>
  );
};
export default ObjectCard;

import { Outlet } from "react-router";
import styled from "styled-components";
import NavBar from "../components/NavBar/NavBar";
import NavMenu from "../components/NavMenu/NavMenu";
import { useAuthStore } from "../hooks/useAuth";
import useWindowSize from "../hooks/useWindowSize";

const PageContainer = styled.div`
  background-color: ${(props) => props.theme.pageBackground};
`;

const PageWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => (props.isMobile ? 70 : 240)}px;
  padding-top: 80px;
`;

const Root = () => {
  const authenticated = useAuthStore.use.authenticated();
  const windowSize = useWindowSize();
  const isMobile = windowSize[0] < 600;

  if (authenticated) {
    return (
      <PageContainer>
        <NavBar />
        <NavMenu />
        <PageWrapper isMobile={isMobile}>
          <Outlet />
        </PageWrapper>
      </PageContainer>
    );
  } else {
    return (
      <div>
        <Outlet />
      </div>
    );
  }
};
export default Root;

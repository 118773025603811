import { differenceInYears, formatDistanceToNowStrict } from "date-fns";
import locale from "date-fns/locale/en-US";

export function hexToRgb(hex: string) {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
  if (normal) return normal.slice(1).map((e) => parseInt(e, 16));

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
  if (shorthand) return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));

  return null;
}
export function hexToRgbString(hex: string, opacity: number) {
  const rgb = hexToRgb(hex);
  if (rgb) {
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
  }
  return "";
}

const formatDistanceLocale = {
  lessThanXSeconds: "{{count}}s",
  xSeconds: "{{count}}s",
  halfAMinute: "30s",
  lessThanXMinutes: "{{count}}m",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}w",
  xWeeks: "{{count}}w",
  aboutXMonths: "{{count}}m",
  xMonths: "{{count}}m",
  aboutXYears: "{{count}}y",
  xYears: "{{count}}y",
  overXYears: "{{count}}y",
  almostXYears: "{{count}}y",
};

export function numberToShortString(value: number): string {
  if (value > 999999) {
    const short = Math.round(value / 1000000);
    return "" + short.toFixed(1) + "m";
  } else if (value > 999) {
    const short = value / 1000;
    return "" + short.toFixed(1) + "k";
  } else {
    return "" + value;
  }
}

export function isSingleEmoji(text: string): boolean {
  //logger.log("isSingleEmoji: " + text);
  if (text === ":+1:") {
    return true;
  }

  return false;
}

export function sanitizeInput(text: string) {
  // Remove trailing spaces.
  let result = text.trim();

  return result;
}

function formatDistance(token: string, count: any, options: any) {
  options = options || {};

  const result = formatDistanceLocale[
    token as keyof typeof formatDistanceLocale
  ].replace("{{count}}", count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return "in " + result;
    } else {
      return result + " ago";
    }
  }
  return result;
}

/**
 * Ex. 1m, 1h, 1d, 1-xw (28w)
 * @param date
 */
export const timeSinceString = (date: Date | string) => {
  //logger.log("date: " + date);

  if (typeof date === "string") {
    return formatDistanceToNowStrict(new Date(date), {
      locale: {
        ...locale,
        formatDistance,
      },
    });
  } else {
    return formatDistanceToNowStrict(date, {
      locale: {
        ...locale,
        formatDistance,
      },
    });
  }
};

export const calculateAge = (date: string | undefined): number | undefined => {
  if (!date) {
    return undefined;
  }
  try {
    const birthdate = new Date(date);
    const today = new Date();

    // info here
    // https://runkit.com/dvolpato/calculate-age-with-date-fns

    const age = differenceInYears(today, birthdate);

    return age;
  } catch (err) {
    return undefined;
  }
};

import { FlexCol, FlexRow } from "../../components/Styled/Container";

const UnresolvedReports = () => {
  return (
    <FlexCol>
      <FlexRow>reports</FlexRow>
    </FlexCol>
  );
};
export default UnresolvedReports;

import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import TextInput from "../../components/TextInput/TextInput";
import { PagingResult, User } from "../../graphql/graphql";
import { USERS } from "../../graphql/queries-gql";
import styled from "styled-components";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import Loader from "../../components/Loader/Loader";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import { formatDateDayOnly } from "../../util/dates";
import { StyledLink } from "../../components/Styled/StyledLink";

const PAGE_SIZE = 10;

const SearchInput = styled(TextInput)`
  color: ${(props) => props.theme.textColor};
  max-width: 300px;
`;

const UsersPage = () => {
  const [fetchUsers, { loading, error }] = useLazyQuery(USERS);
  const [page, setPage] = useState<PagingResult | undefined>(undefined);
  const [users, setUsers] = useState<User[]>([]);
  const theme = useTheme();
  const [regex, setRegex] = useState("");

  const handleSearch = (value: string) => {
    console.log("handleSearch " + value);
    setRegex(value);
    fetchUsers({
      variables: {
        regex: value,
        paging: {
          type: "page",
          pageArgs: {
            page: 1,
            limit: PAGE_SIZE,
          },
        },
      },
      onCompleted: (data) => {
        console.log("users onCompleted", data);
        setUsers(data.users.nodes);
        setPage(data.users.paging);
      },
      onError: (error) => {
        console.log("users onError", error);
      },
    });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    fetchUsers({
      variables: {
        regex: regex,
        paging: {
          type: "page",
          pageArgs: {
            page: value,
            limit: PAGE_SIZE,
          },
        },
      },
      onCompleted: (data) => {
        console.log("users onCompleted", data);
        setUsers(data.users.nodes);
        setPage(data.users.paging);
      },
      onError: (error) => {
        console.log("users onError", error);
      },
    });
  };

  return (
    <>
      <FlexCol>
        <FlexRow>
          <SearchInput
            allowEmpty={true}
            onSubmit={(value) => handleSearch(value)}
            placeHolder={"Search users"}
            icon={<MagnifyingGlassIcon width={20} color={theme.colors.gray} />}
          />
        </FlexRow>
        {users.length > 0 && (
          <FlexRow>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>_id</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Deleted</TableCell>
                    <TableCell>Banned</TableCell>
                    <TableCell>Banned until</TableCell>
                    <TableCell>Premium</TableCell>
                    <TableCell>Credits</TableCell>
                    <TableCell>TransTokens</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => {
                    return (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th">
                          <StyledLink to={`/user/${user._id}`}>
                            {user._id}
                          </StyledLink>
                        </TableCell>

                        <TableCell component="th">{user.username}</TableCell>
                        <TableCell component="th">{user.email}</TableCell>
                        <TableCell component="th">
                          {user.deleted ? "x" : ""}
                        </TableCell>
                        <TableCell component="th">
                          {user.banned ? "x" : ""}
                        </TableCell>
                        <TableCell component="th">
                          {user.bannedUntilDate
                            ? formatDateDayOnly(user.bannedUntilDate)
                            : ""}
                        </TableCell>
                        <TableCell component="th">
                          {user.premium ? "x" : ""}
                        </TableCell>
                        <TableCell component="th">
                          {user.credits && user.credits > 0 ? user.credits : ""}
                        </TableCell>
                        <TableCell component="th">
                          {user.translationTokens && user.translationTokens > 0
                            ? user.translationTokens
                            : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </FlexRow>
        )}
        {page && page.page && page.totalPages && page.totalPages > 1 && (
          <>
            <FlexRow justifyContent="center">
              <Pagination
                size="small"
                variant="outlined"
                count={page.totalPages}
                page={page.page}
                onChange={handlePageChange}
              />
            </FlexRow>
          </>
        )}
        {loading && (
          <FlexRow>
            <Loader />
          </FlexRow>
        )}
        {error && (
          <FlexRow>
            <ErrorAlert error={error} />
          </FlexRow>
        )}
      </FlexCol>
    </>
  );
};
export default UsersPage;

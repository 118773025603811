import { useTheme } from "@mui/material";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import Loader from "../../components/Loader/Loader";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { useMutation, useQuery } from "@apollo/client";
import { GROUPS } from "../../graphql/queries-gql";
import { PhotoThumbnail } from "../unverified-photos/styled";
import ObjectCard from "../../components/ObjectCard/ObjectCard";
import { APPROVE_GROUP } from "../../graphql/mutations-gql";
import { StdButton } from "../../components/Button/Button";

const Groups = () => {
  const theme = useTheme();
  const { data, loading, error } = useQuery(GROUPS);
  const [approveGroup] = useMutation(APPROVE_GROUP);

  const showImage = (src: string) => {
    //window.location.href = src;
    window.open(src, "_blank");
  };

  const handleApprove = (value: boolean, groupId: string) => {
    approveGroup({
      variables: {
        groupId,
        value,
      },
      onCompleted: (data) => {
        console.log("approveGroup onCompleted", data);
      },
      onError: (error) => {
        console.log("approveGroup onError", error);
      },
      refetchQueries: [GROUPS],
    });
  };

  return (
    <FlexCol>
      <FlexRow>
        <Text size={18} weight={600}>
          Public groups for review
        </Text>
      </FlexRow>

      {loading && (
        <FlexRow>
          <Loader />
        </FlexRow>
      )}
      {error && (
        <FlexRow>
          <ErrorAlert error={error} />
        </FlexRow>
      )}

      {data?.groups.map((group: any) => (
        <FlexCol key={group._id}>
          <FlexRow>
            <PhotoThumbnail
              src={group.photo?.thumbnailUrl ?? ""}
              onClick={() => showImage(group.photo?.url ?? "")}
            />
            <StdButton onClick={() => handleApprove(true, group._id)}>
              Approve
            </StdButton>
            <StdButton onClick={() => handleApprove(false, group._id)}>
              Reject
            </StdButton>
          </FlexRow>
          <FlexRow>
            <ObjectCard object={group} title="Group data" />
          </FlexRow>
        </FlexCol>
      ))}
    </FlexCol>
  );
};
export default Groups;
